// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { BsFillFileEarmarkFill, BsThreeDotsVertical } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import {
    LuArrowDown,
    LuArrowLeft,
    LuArrowRight,
    LuArrowUp,
    LuCheck,
    LuChevronDown,
    LuChevronLeft,
    LuChevronRight,
    LuChevronUp,
    LuCog,
    LuDownload,
    LuEye,
    LuEyeOff,
    LuLineChart,
    LuMaximize2,
    LuMinimize2,
    LuQrCode,
    LuRefreshCcw,
    LuScatterChart,
    LuSearch,
    LuTally2,
    LuTally4,
    LuTrash,
    LuX,
    LuZoomIn,
    LuZoomOut,
    LuLink,
} from "react-icons/lu";
import { MdErrorOutline } from "react-icons/md";
import { TbSquareRoot } from "react-icons/tb";

import {
    CircleCheck,
    File as LuFile,
    FlaskConical,
    Home,
    Info,
    Microscope,
    Plus,
    ReceiptText,
    SquareCheck,
    Telescope,
    User,
    Users,
} from "lucide-react";

const NUller = () => null;
export const Icon = {
    AnalyzeImage: NUller,
    ArrowUp: LuArrowUp,
    ArrowDown: LuArrowDown,
    ArrowLeft: LuArrowLeft,
    ArrowRight: LuArrowRight,
    Beamtime: Telescope,
    Charts: LuScatterChart,
    Check: SquareCheck,
    Check2: LuCheck,
    ChevronDown: LuChevronDown,
    ChevronLeft: LuChevronLeft,
    ChevronRight: LuChevronRight,
    ChevronUp: LuChevronUp,
    Cog: LuCog,
    CloudDone: CircleCheck,
    Close: LuX,
    Cross: ImCross,
    Delete: LuTrash,
    Error: MdErrorOutline,
    Form: ReceiptText,
    File: LuFile,
    File2: BsFillFileEarmarkFill,
    FourColumn: LuTally4,
    Hidden: LuEyeOff,
    Home: Home,
    Info: Info,
    LineChart: LuLineChart,
    Link: LuLink,
    MeasurementsImage: NUller,
    Maximize: LuMaximize2,
    Minimize: LuMinimize2,
    Menu: BsThreeDotsVertical,
    Organization: Users,
    Plus: Plus,
    Profile: User,
    QRCode: LuQrCode,
    Request: Microscope,
    Sample: FlaskConical,
    SamplesImage: NUller,
    Search: LuSearch,
    Shown: LuEye,
    TwoColumn: LuTally2,
    graph: {
        Square: TbSquareRoot,
        Download: LuDownload,
        ZoomIn: LuZoomIn,
        ZoomOut: LuZoomOut,
        Reload: LuRefreshCcw,
    },
};
