import type { FC, PropsWithChildren } from "react";
import { useEffect, useState } from "react";

import {
    Avatar,
    Box,
    Center,
    Divider,
    HStack,
    Input,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { type StackProps } from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";

import { isNotFoundError } from "@app/domain/api/errors.ts";
import {
    Organization,
    type OrganizationModel,
} from "@app/domain/api/organization.ts";
import { User } from "@app/domain/api/user.ts";
import { agent } from "@app/Routes/agentRoutes.tsx";
import { routes } from "@app/Routes/routes.ts";
import { UploadAvatarModal } from "@app-components/modals/UploadAvatarModal.tsx";
import { NotFound } from "@app-components/shell/errors/NotFound/NotFound.tsx";

import { AsyncActionButton } from "@mt-components/Button/AsyncActionButton.tsx";
import { type ColumnDef, DataTable } from "@mt-components/DataTable.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { GenericError } from "@mt-components/states/Error/GenericError.tsx";
import { LoadingSpinner } from "@mt-components/states/Loading/LoadingSpinner.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

export const UserPage = () => {
    const userId = agent.user.useParams({ select: (x) => x.userId });

    const userQuery = User.useGetUser(userId);
    const patchUserMutation = User.usePatchUser(userId);

    const uploadAvatarDisclosure = useDisclosure();

    const [isEdited, setIsEdited] = useState(false);
    const [name, setName] = useState("");

    useEffect(() => {
        if (userQuery.data) {
            setName(userQuery.data.name ?? "");
        }
    }, [userQuery.data]);

    const organizationsQuery =
        Organization.useAdminGetUserOrganizationsAll(userId);

    if (userQuery.isPending) {
        return (
            <Center h="100%" w="100%">
                <LoadingSpinner />
            </Center>
        );
    }

    if (userQuery.error && isNotFoundError(userQuery.error)) {
        return <NotFound />;
    }

    if (userQuery.error || !userQuery.data) {
        return <GenericError />;
    }

    const user = userQuery.data;

    return (
        <PageLayout>
            <VStack h="100%" alignItems="stretch">
                <PageHeader
                    actionsId="user-page-header"
                    crumbs={[
                        {
                            id: "user",
                            label: "Users",
                        },
                        {
                            id: "user",
                            label: user.name ? user.name : user.email,
                        },
                    ]}
                    subtitle="Manage user profile"
                />
                <Box w="100%">
                    <Text textStyle="h5" fontWeight="600">
                        General
                    </Text>

                    <PropertyContainer name="Photo">
                        <Box
                            borderRadius="100%"
                            as="button"
                            onClick={uploadAvatarDisclosure.onOpen}
                            p="2px"
                        >
                            <Avatar size="xl" src={user.avatarUrl} />
                        </Box>
                    </PropertyContainer>

                    <InputProperty
                        name="Name"
                        value={user.name ?? ""}
                        onChange={(x) => {
                            setIsEdited(true);
                            setName(x);
                        }}
                    />

                    <PropertyContainer name="Email">
                        <Box>{user.email}</Box>
                    </PropertyContainer>

                    <AsyncActionButton
                        colorScheme="blue"
                        onClick={() => {
                            void patchUserMutation
                                .mutateAsync({ name: name })
                                .then(() => setIsEdited(false));
                        }}
                        isError={patchUserMutation.isError}
                        isLoading={patchUserMutation.isPending}
                        isDisabled={!isEdited}
                    >
                        Save
                    </AsyncActionButton>

                    {uploadAvatarDisclosure.isOpen && (
                        <UploadAvatarModal
                            isOpen={uploadAvatarDisclosure.isOpen}
                            onClose={uploadAvatarDisclosure.onClose}
                            userId={userId}
                        />
                    )}
                </Box>
                <Divider></Divider>
                <Box flex="1" w="100%" overflow="hidden">
                    <Text mb="8" textStyle="h6">
                        Organizations
                    </Text>

                    <DataTable
                        initialState={{
                            sorting: [
                                {
                                    id: "createdAt",
                                    desc: true,
                                },
                            ],
                        }}
                        variant="unstyled"
                        columns={columns}
                        data={organizationsQuery.data ?? []}
                        isSortable
                    />
                </Box>
            </VStack>
        </PageLayout>
    );
};

const PropertyContainer: FC<
    PropsWithChildren<{
        name: string;
    }> &
        StackProps
> = (props) => {
    return (
        <HStack margin="20px 0 20px 0" textStyle="T2">
            <Box w="100%" maxW="200px" textStyle="T2" fontWeight="600">
                {props.name}
            </Box>
            <HStack minW="200px" w="100%" maxW="500px">
                {props.children}
            </HStack>
        </HStack>
    );
};

type InputPropertyProps = {
    name: string;
    value: string;
    onChange?: (v: string) => void;
    isDisabled?: boolean;
};
const InputProperty: FC<InputPropertyProps> = (props) => {
    const [value, setValue] = useState(props.value);

    return (
        <PropertyContainer name={props.name}>
            <Input
                value={value}
                onInput={(e) => setValue(e.currentTarget.value)}
                onBlur={() => {
                    props.onChange?.(value);
                }}
                isDisabled={props.isDisabled}
            />
        </PropertyContainer>
    );
};

const columns: ColumnDef<OrganizationModel>[] = [
    {
        accessorKey: "name",
        header: "Name",
        cell: (cell) => {
            return (
                <Link
                    to={routes.agent.organization.url}
                    params={{ organizationId: cell.row.original.id }}
                >
                    {cell.row.original.name}
                </Link>
            );
        },
    },
    {
        accessorKey: "createdAt",
        header: "Created",
        cell: (cell) => {
            return formatDate(cell.row.original.createdAt);
        },
    },
];
