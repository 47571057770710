import type { FC } from "react";
import { memo, useState } from "react";

import { Button } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Modal } from "@mt-components/Modal/Modal";

import { beamtimesKey, SSWKey } from "src/api/client";
import { useAPI } from "src/App/contexts/APIContext/useApiContext";
import { InputField } from "src/packages/components/Input/InputField";

type Props = {
    beamtimeId: string;
    sampleId: string;
    sampleName: string;
    isOpen: boolean;
    onClose: () => void;
};

export const BeamtimeSetSSW: FC<Props> = memo((props) => {
    const [stickWellId, setStickWellId] = useState<string>("");

    const api = useAPI();
    const queryClient = useQueryClient();
    const createItemsMutation = useMutation({
        mutationFn: async () => {
            return await api.agentClient.POST(
                "/sample-stick-wells/batch/create",
                {
                    body: {
                        items: [{ sampleId: props.sampleId, stickWellId }],
                    },
                },
            );
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: SSWKey() });
            queryClient.invalidateQueries({
                queryKey: beamtimesKey(props.beamtimeId),
            });
            setStickWellId("");
            props.onClose();
        },
    });

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => props.onClose()}
            header="Set Sample-Stick-Well Mapping"
            size="6xl"
            buttonRight={
                <Button
                    onClick={() => {
                        createItemsMutation.mutate();
                    }}
                    isLoading={createItemsMutation.isPending}
                    type="submit"
                    isDisabled={!stickWellId}
                    colorScheme="blue"
                >
                    Create
                </Button>
            }
        >
            <InputField
                label="Sample Name"
                value={props.sampleName}
                isReadOnly={true}
                isDisabled={true}
            ></InputField>
            <InputField
                label="Sample ID"
                value={props.sampleId}
                isReadOnly={true}
                isDisabled={true}
            ></InputField>
            <InputField
                label="Stick Well ID"
                value={stickWellId}
                onInput={(e) => setStickWellId(e.currentTarget.value)}
            ></InputField>
        </Modal>
    );
});

BeamtimeSetSSW.displayName = "BeamtimeSetSSW";
