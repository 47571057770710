export type * as agentSchema from "./gen/schema-agent";
export type * as publicSchema from "./gen/schema-public";

const key = (baseKey: string | string[]) => {
    return (key?: string) => [
        ...(Array.isArray(baseKey) ? baseKey : [baseKey]),
        ...(key ? [key] : []),
    ];
};

export const selfQueryKey = () => ["self"];
export const selfOrganizationsQueryKey = () => ["self", "organizations"];
export const SSWKey = key("ssw");
export const beamtimesKey = key("beamtimes");
