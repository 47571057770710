import type { FC, ReactNode } from "react";

import type { BoxProps } from "@chakra-ui/react";
import {
    Box,
    Button,
    chakra,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tooltip,
} from "@chakra-ui/react";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";

import { Icon } from "@mt-design/icons.tsx";

export type Action<ActionId extends string = string> = {
    id: ActionId;
    icon?: ReactNode;
    onClick: () => void;
    label: ReactNode;
    loadingLabel?: string;
    isTruncated?: boolean;
    isPrimary?: boolean;
    isDisabled?: boolean;
    colorScheme?: string;
    isHidden?: boolean;
    isAction?: boolean;
    help?: string;
    items?: Omit<Action, "items">[];
    hasTooltip?: boolean;
};

export type PageHeaderProps = {
    id?: string;
    actions?: Action[];
    menuActions?: Action[];
};

export const Actions: FC<PageHeaderProps & BoxProps> = ({
    id,
    actions,
    menuActions,
    ...rest
}) => {
    if (!actions?.length && !menuActions?.length) return null;

    return (
        <Box
            display="flex"
            // bg="lightgreen"
            flexDirection="row"
            alignItems="center"
            alignSelf="stretch"
            gap="8px"
            p="4px"
            // height="100%"
            // maxH="48px"
            {...rest}
        >
            {actions
                ? actions
                      .filter((a) => !a.isHidden)
                      .map((a) => {
                          return a.isAction ? (
                              <Menu key={a.id}>
                                  <Tooltip
                                      isDisabled={!a.hasTooltip}
                                      label={a.label}
                                  >
                                      <MenuButton
                                          colorScheme={
                                              a.isPrimary ? "blue" : "gray"
                                          }
                                          as={Button}
                                          size="sm"
                                          rightIcon={<Icon.ChevronDown />}
                                      >
                                          {a.label}
                                      </MenuButton>
                                  </Tooltip>
                                  <MenuList fontSize="md" zIndex="3">
                                      {a.items?.map((item) => (
                                          <MenuItem
                                              isDisabled={item.isDisabled}
                                              onClick={item.onClick}
                                              key={item.id}
                                          >
                                              {item.label}
                                          </MenuItem>
                                      ))}
                                  </MenuList>
                              </Menu>
                          ) : (
                              <Tooltip
                                  key={a.id}
                                  isDisabled={!a.hasTooltip}
                                  label={a.label}
                              >
                                  <Box position="relative">
                                      <LoadingButton
                                          spinnerPlacement="end"
                                          awaitPromise
                                          loadingText={a.loadingLabel}
                                          whiteSpace={
                                              a.isTruncated
                                                  ? "nowrap"
                                                  : "normal"
                                          }
                                          textOverflow={
                                              a.isTruncated
                                                  ? "ellipsis"
                                                  : "clip"
                                          }
                                          isDisabled={a.isDisabled}
                                          key={a.id}
                                          onClick={a.onClick}
                                          overflow={
                                              a.isTruncated
                                                  ? "hidden"
                                                  : "visible"
                                          }
                                          colorScheme={
                                              a.isPrimary
                                                  ? "blue"
                                                  : a.colorScheme ?? "gray"
                                          }
                                      >
                                          {a.label}
                                      </LoadingButton>
                                      <chakra.span
                                          position="absolute"
                                          left={0}
                                          bottom="-34px"
                                          fontSize="10px"
                                      >
                                          {a.help}
                                      </chakra.span>
                                  </Box>
                              </Tooltip>
                          );
                      })
                : null}
            {menuActions?.length ? (
                <Box ml="auto">
                    <Menu id={`${id}-menu`}>
                        <MenuButton
                            aria-label="Menu"
                            colorScheme="gray"
                            as={IconButton}
                            icon={<Icon.Menu />}
                        />
                        <MenuList fontSize="md" zIndex={3}>
                            {menuActions
                                .filter((a) => !a.isHidden)
                                .map((a) => {
                                    return (
                                        <MenuItem
                                            isDisabled={a.isDisabled}
                                            key={a.id}
                                            onClick={a.onClick}
                                        >
                                            {a.label}
                                        </MenuItem>
                                    );
                                })}
                        </MenuList>
                    </Menu>
                </Box>
            ) : null}
        </Box>
    );
};
