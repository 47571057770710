import type { FC } from "react";
import { useState } from "react";

import { Box, Center, Text } from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";

import type {
    CreateOrganizationPayload,
    CreateOrganizationRequest,
    OrganizationModel,
    PatchOrganizationPayload,
} from "@app/domain/api/organization.ts";
import { Organization } from "@app/domain/api/organization.ts";
import { OrganizationsTable } from "@app/pages/user/organizations/Organizations.table.tsx";
import { routes } from "@app/Routes/routes.ts";
import { OrganizationModal } from "@app-components/modals/organization/Organization.modal.tsx";
import { OrganizationEmptyState } from "@app-components/organization/OrganizationEmptyState.tsx";

import type { Crumb } from "@mt-components/Crumbs/Crumbs.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { Icon } from "@mt-design/icons.tsx";

type Props = {
    isFormOpen: boolean;
    organizations: OrganizationModel[];
    onSubmit: (data: CreateOrganizationRequest) => Promise<void>;
    onToggleForm: () => void;
    onGoToOrg: (orgId: string) => void;
};

export const Organizations: FC<Props> = ({
    isFormOpen,
    organizations,
    onSubmit: submit,
    onToggleForm,
    onGoToOrg,
}) => {
    const onSubmit = async (data: CreateOrganizationPayload) => {
        await submit({
            data: data,
        });
        onToggleForm();
    };

    const [toUpdate, setToUpdate] = useState<OrganizationModel | undefined>(
        undefined,
    );

    const { mutateAsync: deleteFn } = Organization.useDelete();
    const { mutateAsync: update } = Organization.useUpdate();

    const onUpdate = (data: PatchOrganizationPayload) => {
        if (toUpdate?.id) {
            void update({
                organizationId: toUpdate.id,
                data: data,
            });
        }

        setToUpdate(undefined);
    };

    const actions = [
        {
            id: "create",
            label: (
                <Box display="flex" gap="8px" alignItems="center">
                    <Icon.Plus size="20px" /> Create
                </Box>
            ),
            isPrimary: true,
            onClick: onToggleForm,
        },
    ];

    const crumbs: Crumb[] = [
        {
            id: "organizations",
            label: (
                <Link to={routes.organizations.url}>
                    <Text textStyle="hx">Organizations</Text>
                </Link>
            ),
        },
    ];

    return (
        <>
            {isFormOpen && (
                <OrganizationModal
                    isOpen={isFormOpen}
                    onCreate={onSubmit}
                    onCancel={onToggleForm}
                />
            )}
            {Boolean(toUpdate) && (
                <OrganizationModal
                    isOpen={Boolean(toUpdate)}
                    onUpdate={onUpdate}
                    onCancel={() => setToUpdate(undefined)}
                    initialData={toUpdate}
                />
            )}

            <PageLayout>
                <Box
                    overflow="hidden"
                    mx="auto"
                    display="flex"
                    flexDirection="column"
                    w="100%"
                    h="100%"
                >
                    {organizations.length === 0 ? (
                        <Center w="100%" h="100%">
                            <OrganizationEmptyState onClick={onToggleForm} />
                        </Center>
                    ) : (
                        <>
                            <PageHeader crumbs={crumbs} actions={actions} />
                            <OrganizationsTable
                                onGoToOrg={onGoToOrg}
                                onUpdate={setToUpdate}
                                items={organizations}
                                onDelete={(orgId) =>
                                    deleteFn({ organizationId: orgId })
                                }
                            />
                        </>
                    )}
                </Box>
            </PageLayout>
        </>
    );
};

Organizations.displayName = "Organization";
