import { partial } from "ramda";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import { applyPageSizeDefault } from "@app/domain/api/tools/applyPageSizeDefault.ts";
import { collectAllPages } from "@app/domain/api/tools/getAllData.ts";
import type { components, operations } from "@app/domain/api/types/v1";

import type { RequestBodyForOperation } from "@mt-tools/fetch.ts";

const entityKey = QueryKeys.organization;

export type PatchOrganizationPayload = RequestBodyForOperation<
    operations["patchOrganization"]
>;

export type PatchOrganizationRequest = {
    organizationId: string;
    data: PatchOrganizationPayload;
};

export type CreateOrganizationPayload = RequestBodyForOperation<
    operations["createOrganization"]
>;

export type CreateOrganizationRequest = {
    data: CreateOrganizationPayload;
};

export type OrganizationModel = components["schemas"]["Organization"];

async function getAllOrganizations(
    api: APIContextValue,
    params?: { query?: { first?: number } },
) {
    return await api.client.GET("/organizations", {
        params: params,
    });
}

type GetAdminOrganizationParams =
    operations["getAdminOrganizations"]["parameters"];

async function getAdmingOrganizations(
    api: APIContextValue,
    params: GetAdminOrganizationParams,
) {
    return await api.client.GET("/admin/organizations", {
        params: applyPageSizeDefault(params),
    });
}

const useGetAll = () => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey],
        queryFn: async () => {
            const fetcher = partial(getAllOrganizations, [api]);
            try {
                const organizations = await collectAllPages<
                    OrganizationModel,
                    operations["getOrganizations"]["parameters"],
                    typeof fetcher
                >(fetcher);
                return organizations;
            } catch {
                throw new Error("Could not fetch organizations");
            }
        },
    });
};

const useAdminGetAll = (
    params?: operations["getAdminOrganizations"]["parameters"],
) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey],
        queryFn: async () => {
            const fetcher = partial(getAdmingOrganizations, [api]);

            try {
                const organizations = await collectAllPages<
                    OrganizationModel,
                    GetAdminOrganizationParams,
                    typeof fetcher
                >(fetcher, params);
                return organizations;
            } catch {
                throw new Error("Could not fetch admin organizations");
            }
        },
    });
};

async function getAdminUserOrganizationsAll(
    api: APIContextValue,
    params: operations["adminGetUserOrganizations"]["parameters"],
) {
    return await api.client.GET("/admin/users/{userId}/organizations", {
        params,
    });
}

const useAdminGetUserOrganizationsAll = (userId: string) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey],
        queryFn: async () => {
            const fetcher = partial(getAdminUserOrganizationsAll, [api]);
            try {
                const organizations = await collectAllPages<
                    OrganizationModel,
                    operations["adminGetUserOrganizations"]["parameters"],
                    typeof fetcher
                >(fetcher, {
                    path: {
                        userId: userId,
                    },
                });
                return organizations;
            } catch {
                throw new Error("");
            }
        },
    });
};

const useGet = (params?: operations["getOrganization"]["parameters"]) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        queryFn: async () => {
            if (!params?.path.organizationId) {
                return undefined;
            }
            return await api.client.GET("/organizations/{organizationId}", {
                params,
            });
        },
    });
};

const useCreate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: CreateOrganizationRequest) => {
            const r = await api.client.POST("/organizations", {
                body: data.data,
            });
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

const useUpdate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: PatchOrganizationRequest) => {
            await api.client.PATCH("/organizations/{organizationId}", {
                params: {
                    path: {
                        organizationId: data.organizationId,
                    },
                },
                body: data.data,
            });

            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return;
        },
    });
};

const useDelete = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: { organizationId: string }) => {
            await api.client.DELETE("/organizations/{organizationId}", {
                params: {
                    path: {
                        organizationId: data.organizationId,
                    },
                },
            });
            return;
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

export const Organization = {
    useGetAll,
    useAdminGetAll,
    useGet,
    useCreate,
    useUpdate,
    useDelete,
    useAdminGetUserOrganizationsAll,
};
