import type { FC } from "react";
import { useState } from "react";

import { z } from "zod";

import { Box, Button, Image, Text } from "@chakra-ui/react";

import { Organization } from "@app/domain/api/organization.ts";

import { InputField } from "@mt-components/Input/InputField.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import swirl from "@mt-assets/swirl.png";
import welcomeBackground from "@mt-assets/welcome_background.png";

const schema = z.object({
    name: z
        .string()
        .max(128, "Team name can have 128 characters maximum")
        .min(3, "Team name must be 3 characters minimum"),
});

export const OrgOnboarding: FC<{
    onSelectOrg: (org: { id: string; name: string }) => void;
}> = ({ onSelectOrg }) => {
    const snackbar = useSnackbar();
    const { mutateAsync: onCreate } = Organization.useCreate();
    const [name, setName] = useState<string | undefined>();

    const result = schema.safeParse({
        name,
    });

    const onConfirm = () => {
        if (!result.success || !name) {
            return;
        }
        void onCreate({
            data: { name },
        }).then((res) => {
            if (res.response.ok && res.data?.id) {
                onSelectOrg({ id: res.data.id, name });
            }

            if (res.error) {
                void snackbar.error({
                    description:
                        "An error occurred. Please reload the page and try again.",
                });
            }
        });
    };

    return (
        <Box display="flex" h="100%">
            <Box
                maxW="1400px"
                boxShadow=""
                display="flex"
                flexGrow="1"
                flexShrink="1"
                flexBasis="500px"
                padding="24px"
                // border="solid 2px green"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    flex={1}
                    display="flex"
                    flexDir="column"
                    padding="24px"
                    height="100%"
                    // border="solid 2px red"
                    maxW="450px"
                >
                    <Box
                        flex="0 0 100px"
                        alignSelf="flex-start"
                        overflow="hidden"
                    >
                        <Image src={swirl} width="90" height="90" alt="swirl" />
                    </Box>
                    <Box
                        my="auto"
                        flex=" 0 0 240px"
                        // border="solid 2px blue"
                    >
                        <Text
                            maxW="300px"
                            display="block"
                            textStyle="h4"
                            my="12px"
                            fontWeight="semibold"
                            textAlign="left"
                        >
                            Create a team
                        </Text>
                        <Text>
                            Your data is stored in your team&apos;s space
                        </Text>

                        <Box
                            data-test-id="center"
                            display="flex"
                            flexDir="column"
                            alignSelf="stretch"
                            w="100%"
                        >
                            {/*This box creates space for the fields error messages. Otherwise, the ui would jump once the error message is displayed*/}
                            <Box
                                data-test-id="flex"
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="center"
                                w="100%"
                                h="68px"
                            >
                                <InputField
                                    formControlProps={{ mt: "12px" }}
                                    w="100%"
                                    name="name"
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    errorMessage={
                                        !result.success && name
                                            ? result.error.errors[0].message
                                            : undefined
                                    }
                                />
                            </Box>
                            <Button
                                isDisabled={!result.success}
                                justifySelf="flex-start"
                                maxW="140px"
                                mt="48px"
                                colorScheme="blue"
                                onClick={onConfirm}
                            >
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                display={{ md: "flex", sm: "none" }}
                bg="#EEE"
                flexGrow="1"
                flexShrink="10"
                flexBasis="300px"
            >
                <Image
                    filter="grayscale(0.2)"
                    opacity={0.1}
                    height="100%"
                    w="100%"
                    src={welcomeBackground}
                    objectFit="cover"
                />
            </Box>
        </Box>
    );
};
