import { type FC, useRef, useState } from "react";
import { useDebounce } from "react-use";

import {
    Avatar,
    Box,
    Button,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tag,
    VStack,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import type { Table } from "@tanstack/react-table";

import { LoadingSpinner } from "@mt-components/states/Loading/LoadingSpinner.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { Icon } from "@mt-design/icons.tsx";

import type { User as UserType } from "src/App/contexts/UserContext";
import { User } from "src/App/domain/api/user";
import { routes } from "src/App/Routes/routes";
import {
    type ColumnDef,
    DataTable,
    makeWidth,
} from "src/packages/components/DataTable";
import { SearchFilterBar } from "src/packages/components/Input/SearchFilterBar";
import { PageHeader } from "src/packages/components/Layout/PageHeader";
import { PageLayout } from "src/packages/components/Layout/PageLayout";

export const UsersPage: FC = () => {
    const userStateMutation = User.usePatchUserActiveState();
    const navigateToUser = useNavigate();

    const tableRef = useRef<Table<UserModel>>(null);
    const [filter, setFilter] = useState<string>("");
    const [debouncedFilter, setDebouncedFilter] = useState<string>("");
    useDebounce(() => setFilter(debouncedFilter), 300, [debouncedFilter]);

    const [endCursor, setEndCursor] = useState<string | null>();

    const snackbar = useSnackbar();

    const usersQuery = User.useGetAllUsers({
        endCursor,
        filter,
    });

    return (
        <PageLayout>
            <Box
                overflow="hidden"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <PageHeader
                    actionsId="users-page-actions"
                    crumbs={[
                        {
                            id: "users",
                            isCurrentPage: true,
                            label: "Users",
                        },
                    ]}
                    subtitle="Users of the momentum transfer platform"
                    menuActions={[]}
                />

                <HStack>
                    <SearchFilterBar
                        value={debouncedFilter}
                        onReset={() => setDebouncedFilter("")}
                        onChange={(e) =>
                            setDebouncedFilter(e.currentTarget.value)
                        }
                        placeholder="Fuzzy search by username and email"
                        isDisabled={userStateMutation.isPending}
                    />
                    <Button
                        isDisabled={
                            !usersQuery.data?.data?.endCursor ||
                            userStateMutation.isPending
                        }
                        onClick={() =>
                            setEndCursor(
                                usersQuery.data?.data?.endCursor ?? null,
                            )
                        }
                    >
                        Next
                    </Button>
                    <Button
                        isDisabled={
                            usersQuery.isPending ||
                            (!filter && !endCursor) ||
                            userStateMutation.isPending
                        }
                        onClick={() => {
                            setEndCursor(null);
                            setFilter("");
                            setDebouncedFilter("");
                        }}
                    >
                        Reset
                    </Button>
                </HStack>

                <Box flex="1" w="100%" overflow="hidden">
                    {usersQuery.isPending || userStateMutation.isPending ? (
                        <VStack justifyContent="center" h="200px">
                            <LoadingSpinner />
                        </VStack>
                    ) : (
                        <DataTable<UserModel>
                            variant="unstyled"
                            instanceRef={tableRef}
                            isSortable
                            columns={columns}
                            data={usersQuery.data?.data?.items ?? []}
                            getRowId={(row) => row.id}
                            onRowClick={(x) => {
                                void navigateToUser({
                                    to: routes.agent.user.url,
                                    params: { userId: x.id },
                                });
                            }}
                            meta={
                                {
                                    onSetActivation: (user, state) => {
                                        userStateMutation
                                            .mutateAsync({
                                                userId: user.id,
                                                state,
                                            })
                                            .then(() => {
                                                snackbar.success(
                                                    `User ${user.email} state changed to ${state}`,
                                                );
                                            })
                                            .catch(() => {
                                                snackbar.error(
                                                    `User ${user.email} state change error`,
                                                );
                                            });
                                    },
                                } satisfies UserTableMeta
                            }
                        />
                    )}
                </Box>
            </Box>
        </PageLayout>
    );
};

type UserModel = Pick<UserType, "avatarUrl" | "id" | "email" | "name"> & {
    state: "active" | "inactive";
};

type UserTableMeta = {
    onSetActivation: (user: UserModel, state: "active" | "inactive") => void;
};

const columns: ColumnDef<UserModel>[] = [
    {
        accessorKey: "id",
        header: "Id",
        thProps: makeWidth("120px"),
    },
    {
        accessorKey: "email",
        header: "Email",
        cell: (cell) => {
            return (
                <HStack>
                    <Avatar size="sm" src={cell.row.original.avatarUrl} />
                    <Box>{cell.row.original.email}</Box>
                </HStack>
            );
        },
        thProps: makeWidth("120px"),
    },
    {
        accessorKey: "name",
        header: "Name",
        thProps: makeWidth("120px"),
    },
    {
        accessorKey: "state",
        header: "Status",
        thProps: makeWidth("30px"),
        cell: (cell) => {
            return (
                <Tag
                    bg={
                        cell.row.original.state === "active"
                            ? "green.500"
                            : "red.500"
                    }
                    color="white"
                >
                    {cell.row.original.state}
                </Tag>
            );
        },
    },
    {
        accessorKey: "menu",
        header: " ",
        thProps: makeWidth("40px"),
        cell: (cell) => {
            const user = cell.row.original;
            const meta = cell.table.options.meta as UserTableMeta;
            return (
                <Menu>
                    <MenuButton
                        bg="white"
                        aria-label="Menu"
                        colorScheme="gray"
                        as={IconButton}
                        icon={<Icon.Menu />}
                    />
                    <MenuList>
                        <MenuItem
                            onClick={() =>
                                meta.onSetActivation(
                                    user,
                                    user.state === "active"
                                        ? "inactive"
                                        : "active",
                                )
                            }
                        >
                            {user.state === "active"
                                ? "Deactivate"
                                : "Activate"}
                        </MenuItem>
                    </MenuList>
                </Menu>
            );
        },
    },
];
