import { type FC, memo, useState } from "react";

import { Box, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { type agentSchema, beamtimesKey, SSWKey } from "src/api/client";
import { BeamtimeSSWFromImage } from "src/App/components/beamtime/BeamtimeSSWFromImage.modal";
import { OrganizationLink } from "src/App/components/OrganizationLink";
import { useAPI } from "src/App/contexts/APIContext/useApiContext";
import { DataTable } from "src/packages/components/DataTable";
import { Actions } from "src/packages/components/Layout/Actions";
import { formatDate } from "src/packages/tools/formatter/localization";
import { downloadFetchBlob } from "src/packages/tools/io/downloadFile";

interface Props {
    beamtimeId: string;
}

export const BeamtimeSSW: FC<Props> = memo((props) => {
    const createSSWFromImageDisclosure = useDisclosure();

    const [selectedRowsIds, setSelectedRowsIds] = useState<string[]>([]);

    const api = useAPI();
    const queryClient = useQueryClient();
    const sswQuery = useQuery({
        queryKey: [...SSWKey(), props.beamtimeId],
        queryFn: () => {
            return api.agentClient.GET("/index/sample-stick-wells", {
                params: {
                    query: {
                        beamtimeId: props.beamtimeId,
                        first: 1000,
                    },
                },
            });
        },
    });

    const sswPDFTemplateDownloadMutation = useMutation({
        mutationFn: async () => {
            return await api.agentClient.POST(
                "/sample-stick-wells/rpc/createSampleStickWellPDFPrintTemplate",
                {
                    body: {
                        beamtimeId: props.beamtimeId,
                    },
                    parseAs: "blob",
                },
            );
        },
        onSuccess(data) {
            downloadFetchBlob(data);
        },
    });

    const sswDeleteMutation = useMutation({
        mutationFn: async () => {
            return await api.agentClient.POST(
                "/sample-stick-wells/batch/delete",
                {
                    body: {
                        items: (sswQuery.data?.data?.items ?? [])
                            .filter((_, i) => selectedRowsIds.includes(`${i}`))
                            .map((x) => ({ sampleId: x.sampleId })),
                    },
                },
            );
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: SSWKey() });
            queryClient.invalidateQueries({
                queryKey: beamtimesKey(props.beamtimeId),
            });
        },
    });

    return (
        <Box w="100%" h="100%">
            <BeamtimeSSWFromImage
                isOpen={createSSWFromImageDisclosure.isOpen}
                onClose={createSSWFromImageDisclosure.onClose}
                onSubmit={() => {}}
            />

            <Actions
                actions={[
                    {
                        id: "print-ssw-template",
                        label: "Download Print Template",
                        isAction: false,
                        onClick: async () => {
                            return await sswPDFTemplateDownloadMutation.mutateAsync();
                        },
                    },
                    {
                        id: "create-ssw-from-image",
                        label: "Set From Image",
                        isAction: false,
                        onClick: createSSWFromImageDisclosure.onOpen,
                    },
                    {
                        id: "Delete",
                        label: "Delete",
                        isAction: false,
                        isDisabled: !selectedRowsIds.length,
                        onClick: async () => {
                            return await sswDeleteMutation.mutateAsync();
                        },
                    },
                ]}
            ></Actions>

            <DataTable<agentSchema.components["schemas"]["SSWIndexItem"]>
                variant="unstyled"
                columns={[
                    {
                        accessorKey: "organizationName",
                        header: "Organization",
                        cell: (x) => (
                            <OrganizationLink
                                organizationId={x.row.original.organizationId}
                                label={x.getValue() as string}
                            ></OrganizationLink>
                        ),
                    },
                    {
                        accessorKey: "sampleName",
                        header: "Sample",
                    },
                    {
                        accessorKey: "sampleId",
                        header: "Sample ID",
                    },
                    {
                        accessorKey: "stickWellId",
                        header: "Stick Well ID",
                    },
                    {
                        accessorKey: "createdAt",
                        header: "Created At",
                        cell: (cell) => formatDate(cell.row.original.updatedAt),
                    },
                    {
                        accessorKey: "updatedAt",
                        header: "Updated At",
                        cell: (cell) => formatDate(cell.row.original.updatedAt),
                    },
                ]}
                data={sswQuery.data?.data?.items ?? []}
                isSortable
                isSelectable
                onSelectedRowsChange={setSelectedRowsIds}
            />
        </Box>
    );
});
BeamtimeSSW.displayName = "BeamtimeSSW";
