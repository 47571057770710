import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import {
    mapErrorResponseToThrownError,
    NetWorkError,
} from "@app/domain/api/errors.ts";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import type { components } from "@app/domain/api/types/v1";

export const useGetAllUsers = (params: {
    endCursor: string | null | undefined;
    filter: string;
}) => {
    const pageSize = 50;

    const api = useAPI();
    return useQuery({
        queryKey: ["users", params.endCursor, params.filter],
        queryFn: async () => {
            return await api.client.GET("/admin/users", {
                params: {
                    query: {
                        fuzzy: params.filter || undefined,
                        after: params.endCursor ?? undefined,
                        first: pageSize,
                    },
                },
            });
        },
    });
};

export const useGetUser = (userId: string) => {
    const api = useAPI();

    return useQuery({
        queryKey: [QueryKeys.users, userId],
        queryFn: async () => {
            const response = await api.client
                .GET("/admin/users/{userId}", {
                    params: { path: { userId } },
                })
                .catch(() => {
                    throw new NetWorkError();
                });

            if (!response.response.ok) {
                mapErrorResponseToThrownError(response);
            }

            return response.data;
        },
    });
};

export const usePatchUser = (userId: string) => {
    const api = useAPI();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: components["schemas"]["PatchUserPayload"]) =>
            api.client.PATCH("/admin/users/{userId}", {
                params: { path: { userId: userId } },
                body: data,
            }),
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.users],
            }),
    });
};

export const usePatchUserActiveState = () => {
    const api = useAPI();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (ctx: {
            userId: string;
            state: "active" | "inactive";
        }) =>
            await api.client.PATCH("/admin/users/{userId}/activation", {
                params: {
                    path: {
                        userId: ctx.userId,
                    },
                },
                body: {
                    newState: ctx.state,
                },
            }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["users"],
            });
        },
    });
};

export const User = {
    useGetUser,
    usePatchUser,
    usePatchUserActiveState,
    useGetAllUsers,
};
