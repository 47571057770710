import type { FC } from "react";

import { Link } from "@chakra-ui/react";
import { interpolatePath, useNavigate } from "@tanstack/react-router";

import { useRoleContext } from "../contexts/RoleContext";
import { Role } from "../domain/auth";
import { routes } from "../Routes/routes";

export type RequestLinkProps = {
    organizationId: string;
    requestId: string;
    label: string;
};

export const RequestLink: FC<RequestLinkProps> = (props) => {
    const roleCtx = useRoleContext();
    const navigate = useNavigate();

    return (
        <Link
            href={interpolatePath(routes.agent.organization.url, {
                organizationId: props.organizationId,
            })}
            onClick={(e) => {
                e.preventDefault();
                navigate({
                    to:
                        roleCtx.role === Role.AGENT
                            ? routes.agent.measurementGroupDetail.url
                            : routes.user.measurementGroupDetail.url,
                    params: {
                        orgId: props.organizationId,
                        orderId: props.requestId,
                    },
                });
            }}
        >
            {props.label}
        </Link>
    );
};
