import type { FC } from "react";

import { useFormik } from "formik";
import type { Promisable } from "type-fest";
import { z, ZodError } from "zod";

import { Box, Text } from "@chakra-ui/react";

import { Authz } from "@app/domain/api/authz.ts";
import { OrganizationRoles } from "@app/domain/services/organization.ts";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { InputField } from "@mt-components/Input/InputField.tsx";
import { StaticField2 } from "@mt-components/Input/StaticField2.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

type Props = {
    isOpen: boolean;
    orgId: string;
    onClose: () => Promisable<unknown>;
};

const fields = {
    EMAIL: "email",
    ROLE: "role",
} as const;

const rola = [
    OrganizationRoles.OWNER,
    OrganizationRoles.VIEWER,
    OrganizationRoles.COLLABORATOR,
] as const;
const zodSchema = z.object({
    [fields.EMAIL]: z.string(),
    [fields.ROLE]: z.enum(rola),
});

type Schema = z.infer<typeof zodSchema>;

const validateForm = (values: Schema) => {
    try {
        zodSchema.parse(values);
    } catch (error) {
        if (error instanceof ZodError) {
            return error.formErrors.fieldErrors;
        }
    }
};

const initialValues = {
    [fields.EMAIL]: "",
    [fields.ROLE]: OrganizationRoles.OWNER,
};

export const InviteUserModal: FC<Props> = ({ orgId, onClose, isOpen }) => {
    const { mutateAsync } = Authz.useInviteUser(orgId);
    const snackbar = useSnackbar();

    const form = useFormik<Schema>({
        initialValues: initialValues,
        onSubmit: () => {},
        validateOnBlur: true,
        validateOnChange: false,
        validate: (values) => {
            return validateForm(values);
        },
    });

    const onInvite = async () => {
        if (!zodSchema.safeParse(form.values).success) {
            return;
        }
        const data = {
            email: form.values.email,
            role: form.values.role,
        };
        await mutateAsync(data).then((r) => {
            if (r.response.ok) {
                snackbar.success({
                    description: (
                        <>
                            {data.email} added to the organization as{" "}
                            <Text as="span" fontWeight="600">
                                {data.role}
                            </Text>
                        </>
                    ),
                });
                onClose();
                return;
            }

            snackbar.error({
                description: (
                    <>{data.email} could not be added to the organization.</>
                ),
            });
            onClose();
        });
    };

    return (
        <Modal
            header="Invite user"
            isOpen={isOpen}
            onClose={onClose}
            onOverlayClick={onClose}
            buttonRight={
                <LoadingButton
                    awaitPromise
                    colorScheme="blue"
                    isDisabled={!form.isValid}
                    onClick={onInvite}
                >
                    Confirm
                </LoadingButton>
            }
        >
            <Box w="100%" h="95px" overflow="auto" px="4px" my="12px">
                <Box display="flex" justifyContent="flex-start" gap="12px">
                    <InputField
                        name={fields.EMAIL}
                        label="Email address"
                        value={form.values.email}
                        errorMessage={form.errors.email}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {/*<InputField*/}
                    {/*    name={fields.EMAIL}*/}
                    {/*    label="Role"*/}
                    {/*    value={form.values.email}*/}
                    {/*    errorMessage={form.errors.email}*/}
                    {/*    onChange={form.handleChange}*/}
                    {/*    onBlur={form.handleBlur}*/}
                    {/*/>*/}
                    <StaticField2 title="Role" value="Owner" />
                </Box>
            </Box>
        </Modal>
    );
};
