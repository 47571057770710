import { partial } from "ramda";

import { useQuery } from "@tanstack/react-query";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import { applyPageSizeDefault } from "@app/domain/api/tools/applyPageSizeDefault.ts";
import { collectAllPages } from "@app/domain/api/tools/getAllData.ts";
import type { components, operations } from "@app/domain/api/types/v1";

export type OrganizationModel = components["schemas"]["Organization"];

async function getAdmingOrganizations(
    api: APIContextValue,
    params: operations["getAdminOrganizations"]["parameters"],
) {
    return await api.client.GET("/admin/organizations", {
        params: applyPageSizeDefault(params),
    });
}

const useAdminGetAll = (
    params?: operations["getAdminOrganizations"]["parameters"],
) => {
    const api = useAPI();
    return useQuery({
        queryKey: [QueryKeys.organization],
        queryFn: async () => {
            const fetcher = partial(getAdmingOrganizations, [api]);

            try {
                const organizations = await collectAllPages<
                    OrganizationModel,
                    operations["getAdminOrganizations"]["parameters"],
                    typeof fetcher
                >(fetcher, params);
                return organizations;
            } catch {
                throw new Error("Could not fetch admin organizations");
            }
        },
    });
};

async function getAdminUserOrganizationsAll(
    api: APIContextValue,
    params: operations["adminGetUserOrganizations"]["parameters"],
) {
    return await api.client.GET("/admin/users/{userId}/organizations", {
        params,
    });
}

const useAdminGetUserOrganizationsAll = (userId: string) => {
    const api = useAPI();
    return useQuery({
        queryKey: [QueryKeys.organization],
        queryFn: async () => {
            const fetcher = partial(getAdminUserOrganizationsAll, [api]);
            try {
                const organizations = await collectAllPages<
                    OrganizationModel,
                    operations["adminGetUserOrganizations"]["parameters"],
                    typeof fetcher
                >(fetcher, {
                    path: {
                        userId: userId,
                    },
                });
                return organizations;
            } catch {
                throw new Error("");
            }
        },
    });
};

const useGetOrganizationUsers = (
    params: operations["getOrganizationUsers"]["parameters"],
) => {
    const api = useAPI();
    return useQuery({
        queryKey: [QueryKeys.organization, params.path.organizationId, "users"],
        queryFn: async () => {
            if (!params.path.organizationId) {
                return undefined;
            }
            try {
                const promise = await api.client.GET(
                    "/admin/organizations/{organizationId}/users",
                    {
                        params,
                    },
                );

                if (!promise.response.ok) {
                    throw new Error("Could not fetch organization users");
                }
                return promise.data;
            } catch {
                throw new Error("Could not fetch organization users");
            }
        },
    });
};

export const AgentOrganization = {
    useAdminGetAll,
    useAdminGetUserOrganizationsAll,
    useGetOrganizationUsers,
};
