import { memo } from "react";

import { useDisclosure } from "@chakra-ui/react";
import { RouteApi } from "@tanstack/react-router";

import type { CreateOrganizationRequest } from "@app/domain/api/organization.ts";
import { Organization } from "@app/domain/api/organization.ts";
import { NotFound } from "@app-components/shell/errors/NotFound/NotFound.tsx";

import { getById } from "@mt-tools/iterating/filter.ts";

import { OrganizationDetail } from "./OrganizationDetail.tsx";

const Route = new RouteApi({
    id: "/organizations/$orgId",
});

export const OrganizationDetailsPage = memo(() => {
    const { mutateAsync: submit } = Organization.useCreate();
    const orgQuery = Organization.useGetAll();
    const params = Route.useParams();
    const org = orgQuery.data?.find(getById(params.orgId));
    const { isOpen, onToggle } = useDisclosure();

    const onSubmit = async (data: CreateOrganizationRequest) => {
        await submit(data);
    };

    if (!org) {
        return <NotFound />;
    }

    return (
        <OrganizationDetail
            organization={org}
            orgId={params.orgId}
            isFormOpen={isOpen}
            onSubmit={onSubmit}
            onToggleForm={onToggle}
        />
    );
});

OrganizationDetailsPage.displayName = "OrganizationDetailsPage";
