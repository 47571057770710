import { memo } from "react";

import { useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import type { CreateOrganizationRequest } from "@app/domain/api/organization.ts";
import { Organization } from "@app/domain/api/organization.ts";
import { routes } from "@app/Routes/routes.ts";

import { Organizations as OrganizationC } from "./Organizations.tsx";

export const OrganizationsPage = memo(() => {
    const { isOpen, onToggle } = useDisclosure();
    const navigate = useNavigate();
    const submit = Organization.useCreate();
    const organizationsQuery = Organization.useGetAll();

    const onSubmit = async (data: CreateOrganizationRequest) => {
        await submit.mutateAsync(data);
    };

    const onGoToOrg = (orgId: string) => {
        void navigate({
            to: routes.organizationDetails.url,
            params: {
                orgId,
            },
        });
    };

    return (
        <OrganizationC
            organizations={organizationsQuery.data ?? []}
            isFormOpen={isOpen}
            onSubmit={onSubmit}
            onToggleForm={onToggle}
            onGoToOrg={onGoToOrg}
        />
    );
});

OrganizationsPage.displayName = "OrganizationsPage";
