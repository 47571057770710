import { type FC } from "react";

import { Box, Center, HStack } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import type { OrganizationModel } from "@app/domain/api/agent/organization.ts";
import { AgentOrganization } from "@app/domain/api/agent/organization.ts";

import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

import { useDebouncedSearch } from "@mt-hooks/useSearch.tsx";

import { routes } from "src/App/Routes/routes";
import {
    type ColumnDef,
    DataTable,
    makeWidth,
} from "src/packages/components/DataTable";
import { SearchFilterBar } from "src/packages/components/Input/SearchFilterBar";
import { PageHeader } from "src/packages/components/Layout/PageHeader";
import { PageLayout } from "src/packages/components/Layout/PageLayout";

export const OrganizationsPage: FC = () => {
    const navigate = useNavigate();
    const organizationQuery = AgentOrganization.useAdminGetAll();

    const { filteredOrders, onClear, onChange } =
        useDebouncedSearch<OrganizationModel>({
            items: organizationQuery.data ?? [],
            keys: ["name"],
        });

    return (
        <PageLayout>
            <Box
                overflow="hidden"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <PageHeader
                    crumbs={[
                        {
                            id: "organizations",
                            isCurrentPage: true,
                            label: "Organizations",
                        },
                    ]}
                    subtitle="All organizations"
                    menuActions={[]}
                />

                <HStack>
                    <SearchFilterBar
                        onReset={onClear}
                        onChange={onChange}
                        placeholder="Filter by name"
                    />
                </HStack>

                <Box flex="1" w="100%" overflow="hidden">
                    {organizationQuery.isPending ? (
                        <Center width="100%" h="100%">
                            <LoadingSwirl />
                        </Center>
                    ) : (
                        <DataTable<OrganizationModel>
                            variant="unstyled"
                            isSortable
                            columns={columns}
                            data={filteredOrders}
                            getRowId={(row) => row.id}
                            onRowClick={(x) => {
                                void navigate({
                                    to: routes.agent.organization.url,
                                    params: { organizationId: x.id },
                                });
                            }}
                        />
                    )}
                </Box>
            </Box>
        </PageLayout>
    );
};

const columns: ColumnDef<OrganizationModel>[] = [
    {
        accessorKey: "id",
        header: "Id",
        thProps: makeWidth("120px"),
    },
    {
        accessorKey: "name",
        header: "Name",
        thProps: makeWidth("120px"),
    },
];
