import type { FC } from "react";

import {
    Box,
    Button,
    type ButtonProps,
    HStack,
    Tooltip,
} from "@chakra-ui/react";

import { Icon } from "src/packages/solid-design/icons";

export type AsyncActionButtonProps = ButtonProps & {
    onClick?: () => void;
    errorText?: string;
    isError?: boolean;
};

export const AsyncActionButton: FC<AsyncActionButtonProps> = ({
    errorText,
    isError,
    ...props
}) => {
    return (
        <HStack>
            <Button {...props} />
            <Box w="20px">
                {isError ? (
                    <Tooltip
                        label={
                            errorText ??
                            "Some error occurred while processing request"
                        }
                    >
                        <Icon.Info color="red"></Icon.Info>
                    </Tooltip>
                ) : null}
            </Box>
        </HStack>
    );
};
