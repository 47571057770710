import type { FC } from "react";

import { indexBy } from "ramda";

import { Avatar, Box, Tag, Text } from "@chakra-ui/react";
import { Link, useNavigate } from "@tanstack/react-router";
import type { ColumnDef } from "@tanstack/react-table";

import { AgentOrganization } from "@app/domain/api/agent/organization.ts";
import type { OrganizationAuthzRecord } from "@app/domain/api/authz.ts";
import { Authz } from "@app/domain/api/authz.ts";
import type { OrganizationModel } from "@app/domain/api/organization.ts";
import type { OrganizationRole } from "@app/domain/services/organization.ts";
import { OrganizationRoles } from "@app/domain/services/organization.ts";
import { routes } from "@app/Routes/routes.ts";

import type { Crumb } from "@mt-components/Crumbs/Crumbs.tsx";
import { Crumbs } from "@mt-components/Crumbs/Crumbs.tsx";
import { DataTable } from "@mt-components/DataTable.tsx";
import { SearchFilterBar } from "@mt-components/Input/SearchFilterBar.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { useSearch } from "@mt-hooks/useSearch.tsx";

type Props = {
    organization: OrganizationModel;
};

type Member = {
    id: string;
    name?: string;
    email: string;
    role: OrganizationRole;
    avatarUrl?: string;
};

const columns: ColumnDef<Member>[] = [
    {
        accessorKey: "avatar",
        header: "Avatar",
        cell: (cell) => {
            return (
                <Box>
                    <Avatar size="sm" src={cell.cell.row.original.avatarUrl} />
                </Box>
            );
        },
    },
    {
        accessorKey: "name",
        header: "Name",
        cell: (cell) => {
            return <Box>{cell.cell.row.original.name}</Box>;
        },
    },
    {
        accessorKey: "email",
        header: "Email",
        cell: (cell) => {
            return <Box>{cell.cell.row.original.email}</Box>;
        },
    },
    {
        accessorKey: "role",
        header: "Role",
        cell: (ctx) => {
            if (ctx.cell.row.original.role === OrganizationRoles.OWNER) {
                return (
                    <Tag colorScheme="blue">{ctx.cell.row.original.role} </Tag>
                );
            }

            if (ctx.cell.row.original.role === OrganizationRoles.COLLABORATOR) {
                return (
                    <Tag colorScheme="purple">
                        {ctx.cell.row.original.role}{" "}
                    </Tag>
                );
            }

            return <Tag colorScheme="green">{ctx.cell.row.original.role} </Tag>;
        },
    },
];

export const OrganizationDetail: FC<Props> = ({ organization }) => {
    const navigate = useNavigate();
    const orgAuthzQuery = Authz.useGetOrganizationRecords(organization.id);

    const orgUsersQuery = AgentOrganization.useGetOrganizationUsers({
        path: {
            organizationId: organization.id,
        },
    });

    const authRecordByUserId: Record<
        string,
        OrganizationAuthzRecord | undefined
    > = indexBy((record) => record.userId, orgAuthzQuery.data ?? []);

    const members: Member[] =
        orgUsersQuery.data?.map((user) => ({
            ...user,
            role: authRecordByUserId[user.id]?.role ?? OrganizationRoles.VIEWER,
        })) ?? [];

    const search = useSearch<Member>({
        items: members,
        keys: ["name", "email", "role"],
    });

    const crumbs: Crumb[] = [
        {
            id: "organizations",
            label: (
                <Link to={routes.agent.organizations.url}>
                    <Text textStyle="hx">Organizations</Text>
                </Link>
            ),
        },
        {
            id: "detail",
            label: organization.name,
            isCurrentPage: true,
        },
    ];

    return (
        <PageLayout>
            <Box
                overflow="hidden"
                mx="auto"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <Crumbs items={crumbs} />
                <PageHeader
                    mt="12px"
                    title={`${organization.name}'s members`}
                    actions={[]}
                />
                <Box
                    mb="14px"
                    display="flex"
                    flexWrap="wrap"
                    w="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        as="div"
                        display="block"
                        textStyle="h5"
                        flex="0 0 240px"
                    >
                        <Text as="span">All members </Text>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <SearchFilterBar
                            placeholder="Search by email or role"
                            value={search.searchTerm}
                            onReset={search.onClear}
                            onChange={search.onChange}
                        />
                    </Box>
                </Box>
                <Box h="300px">
                    <DataTable<Member>
                        state={{
                            columnVisibility: {
                                menu: false,
                            },
                        }}
                        variant="unstyled"
                        data={search.filteredOrders}
                        columns={columns}
                        onRowClick={(row) => {
                            void navigate({
                                to: routes.agent.user.url,
                                params: { userId: row.id },
                            });
                        }}
                    />
                </Box>
            </Box>
        </PageLayout>
    );
};

OrganizationDetail.displayName = "OrganizationDetail";
