import type { FC } from "react";
import { memo, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import pMap from "p-map";

import {
    Box,
    Button,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Modal } from "@mt-components/Modal/Modal.tsx";

import { beamtimesKey, SSWKey } from "src/api/client";
import { useAPI } from "src/App/contexts/APIContext/useApiContext";
import { DataTable } from "src/packages/components/DataTable";

import type {
    BeamtimeSSWFromImageParseItemRef,
    ParseStatus,
} from "./BeamtimeSSWFromImageParseItem";
import { BeamtimeSSWFromImageParseItem } from "./BeamtimeSSWFromImageParseItem";

type ParsedSampleStickWellMappingItem = {
    image: string;
    data?: string;
};

type ParsedSampleStickWellMapping = {
    pairsImage?: string;
    decodedPairs: {
        sample: ParsedSampleStickWellMappingItem;
        stickWell: ParsedSampleStickWellMappingItem;
    }[];
};

type ParseItem = {
    file: File;
    status: ParseStatus;
    parsedData?: ParsedSampleStickWellMapping;
};

type ResultMapping = { sampleId: string; stickWellId: string };

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (images: ParseItem[]) => void;
};

export const BeamtimeSSWFromImage: FC<Props> = memo((props) => {
    const [parseItems, setParseItems] = useState<ParseItem[]>([]);
    const parseItemRefs = useRef<(BeamtimeSSWFromImageParseItemRef | null)[]>(
        [],
    );

    const [aggregatedItems, setAggregatedItems] = useState<ResultMapping[]>([]);
    const [duplicatesRemoved, setDuplicatesRemoved] = useState<number>(0);
    const [unparsed, setUnparsed] = useState<number>(0);

    const api = useAPI();
    const queryClient = useQueryClient();
    const createItemsMutation = useMutation({
        mutationFn: async () => {
            return await api.agentClient.POST(
                "/sample-stick-wells/batch/create",
                {
                    body: {
                        items: aggregatedItems,
                    },
                },
            );
        },
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: SSWKey() });
            queryClient.invalidateQueries({ queryKey: beamtimesKey() });
            close();
        },
    });

    const { getRootProps, getInputProps } = useDropzone({
        accept: { "image/jpeg": [], "image/png": [], "image/jpg": [] },
        onDrop: (acceptedFiles: File[]) => {
            const newImages = acceptedFiles.map((file) => ({
                file,
                status: "new" as const,
            }));
            setParseItems((prev) => [...prev, ...newImages]);
        },
    });

    const close = () => {
        setParseItems([]);
        parseItemRefs.current = [];
        props.onClose();
    };

    useEffect(() => {
        const newAggregatedItems: ResultMapping[] = [];
        let newDuplicatesRemoved: number = 0;
        let newUnparsed: number = 0;

        const samplesSet = new Set<string>();

        for (const item of parseItems) {
            for (const pair of item.parsedData?.decodedPairs ?? []) {
                const sampleId = pair.sample.data;
                const stickWellId = pair.stickWell.data;

                if (!sampleId || !stickWellId) {
                    newUnparsed++;
                    continue;
                }

                if (samplesSet.has(sampleId)) {
                    newDuplicatesRemoved++;
                    continue;
                }
                samplesSet.add(sampleId);

                newAggregatedItems.push({
                    sampleId: sampleId,
                    stickWellId: stickWellId,
                });
            }
        }

        setAggregatedItems(newAggregatedItems);
        setDuplicatesRemoved(newDuplicatesRemoved);
        setUnparsed(newUnparsed);
    }, [parseItems]);

    const [isParsing, setIsParsing] = useState<boolean>(false);

    const onParseImages = () => {
        setIsParsing(true);
        pMap(
            parseItemRefs.current,
            async (ref, index) => {
                if (ref && parseItems[index].status === "new") {
                    await ref.parseAsync();
                }
            },
            { concurrency: 16 },
        ).finally(() => setIsParsing(false));
    };

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={() => close()}
            header="Set Sample-Stick-Well Mapping from Image"
            size="full"
        >
            <Tabs size="lg" variant="line" flex={1} fontSize="md" height="90vh">
                <TabList h="36px">
                    <Tab>Upload & Parse</Tab>
                    <Tab>Review & Save</Tab>
                </TabList>
                <TabPanels h="90vh">
                    <TabPanel px={0}>
                        <Box w="100%" h="87vh" overflow="auto">
                            <Box
                                {...getRootProps()}
                                border="2px dashed"
                                borderColor="gray.300"
                                borderRadius="md"
                                p="6"
                                textAlign="center"
                                cursor="pointer"
                                mb="4"
                            >
                                <input {...getInputProps()} />
                                <Text>
                                    Drag & drop images here, or click to select
                                    files
                                </Text>
                                <Text fontSize="sm" color="gray.500">
                                    (Only *.jpeg, *.jpg, *.png images will be
                                    accepted)
                                </Text>
                            </Box>

                            <Flex justifyContent="space-between" mb="4">
                                <Text>
                                    Duplicates removed: {duplicatesRemoved}
                                </Text>
                                <Text>Unparsed: {unparsed}</Text>
                                <Button
                                    colorScheme="blue"
                                    onClick={onParseImages}
                                    isLoading={isParsing}
                                    isDisabled={parseItems.length === 0}
                                >
                                    Parse Images
                                </Button>
                            </Flex>

                            {/* Uploaded Images Section */}
                            {parseItems.map((item, index) => (
                                <BeamtimeSSWFromImageParseItem
                                    key={index}
                                    ref={(el) =>
                                        (parseItemRefs.current[index] = el)
                                    } // Store ref for each child
                                    image={item.file}
                                    onRemove={() =>
                                        setParseItems((prev) =>
                                            prev.filter((_, i) => i !== index),
                                        )
                                    }
                                    onParseSuccess={(data) => {
                                        setParseItems((prev) =>
                                            prev.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          status: "success",
                                                          parsedData: data,
                                                      }
                                                    : item,
                                            ),
                                        );
                                    }}
                                    onParseError={() => {
                                        setParseItems((prev) =>
                                            prev.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          status: "error",
                                                      }
                                                    : item,
                                            ),
                                        );
                                    }}
                                />
                            ))}
                        </Box>
                    </TabPanel>
                    <TabPanel h="100%">
                        <Box h="100%" overflow="hidden">
                            <Flex justifyContent="space-between" mb="4">
                                <Text>
                                    Duplicates removed: {duplicatesRemoved}
                                </Text>
                                <Text>Unparsed: {unparsed}</Text>
                                <Button
                                    colorScheme="green"
                                    onClick={() => {
                                        createItemsMutation.mutate();
                                    }}
                                    isLoading={createItemsMutation.isPending}
                                    isDisabled={!aggregatedItems.length}
                                >
                                    Save
                                </Button>
                            </Flex>
                            <DataTable<ResultMapping>
                                variant="unstyled"
                                columns={[
                                    {
                                        accessorKey: "sampleId",
                                        header: "SampleId",
                                    },
                                    {
                                        accessorKey: "stickWellId",
                                        header: "Stick Well Id",
                                    },
                                ]}
                                data={aggregatedItems}
                                isSortable
                            />
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Modal>
    );
});

BeamtimeSSWFromImage.displayName = "BeamtimeSSWFromImage";
