import type { FC } from "react";
import { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { FileUpload } from "@mt-components/Input/FileUpload.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { MiB } from "@mt-tools/units.tsx";

import { selfQueryKey } from "src/api/client";
import { useAPI } from "src/App/contexts/APIContext/useApiContext";
import { useRoleContext } from "src/App/contexts/RoleContext";
import { Role } from "src/App/domain/auth";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    userId: string;
};

const maxFileSize = 2;
const maxFileSizeWUnit = maxFileSize * MiB;

export const UploadAvatarModal: FC<Props> = (props) => {
    const [file, setFile] = useState<File | null>(null);
    const snackbar = useSnackbar();

    const roleCtx = useRoleContext();
    const api = useAPI();

    const queryClient = useQueryClient();
    const uploadMutation = useMutation({
        mutationFn: async (file: File) => {
            const formData = new FormData();
            formData.append("file", file);

            if (roleCtx.role === Role.AGENT) {
                await api.agentClient.PUT("/self/avatar", {
                    bodySerializer: (x) => x,
                    // @ts-expect-error wrong typing from generator
                    body: formData,
                });
            } else {
                await api.publicClient.PUT("/self/avatar", {
                    bodySerializer: (x) => x,
                    // @ts-expect-error wrong typing from generator
                    body: formData,
                });
            }
        },
        onSuccess: () =>
            queryClient.invalidateQueries({ queryKey: selfQueryKey() }),
    });

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            buttonRight={
                <LoadingButton
                    colorScheme="blue"
                    awaitPromise
                    isDisabled={!file}
                    onClick={async () => {
                        if (!file) return;

                        try {
                            await uploadMutation.mutateAsync(file);
                            snackbar.success("Profile image was updated");
                            props.onClose();
                            setFile(null);
                        } catch {
                            snackbar.error("Profile image update failed");
                            props.onClose();
                            setFile(null);
                        }
                    }}
                >
                    Upload
                </LoadingButton>
            }
            header="Upload profile image"
        >
            <FileUpload
                maxFiles={1}
                maxFileSize={maxFileSizeWUnit}
                uploadLabel={`Select file ${maxFileSize} Mb max`}
                setFiles={(files) => {
                    if (files?.acceptedFiles.length === 1) {
                        setFile(files.acceptedFiles[0]);
                        return;
                    }

                    if (!files) {
                        setFile(null);
                    }
                }}
                clearFiles={() => setFile(null)}
                accept={[".png", ".jpg", ".jpeg"]}
            />
        </Modal>
    );
};
