import { createContext, memo, type PropsWithChildren, useEffect } from "react";

import { useLatestRef } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";

import {
    type agentSchema,
    type publicSchema,
    selfQueryKey,
} from "src/api/client";

import { useAPI } from "./APIContext/useApiContext";
import { useLoadingContext } from "./GlobalLoader/LoadingProvider/useLoadingContext";
import { useRoleContext } from "./RoleContext";

import { Role } from "../domain/auth";

export type User = {
    id: string;
    name?: string;
    email: string;
    avatarUrl?: string;
};

export type Self =
    | publicSchema.components["schemas"]["Self"]
    | agentSchema.components["schemas"]["Self"];

export const authzLoadingKey = "authz";
export const UserContext = createContext<Self | null>(null);

UserContext.displayName = "UserContext";

function usePublicSelfQuery(api: APIContextValue, role: Role) {
    return useQuery({
        queryKey: [...selfQueryKey(), "public"],
        queryFn: () => api.publicClient.GET("/self"),
        staleTime: Infinity,
        enabled: role === Role.USER,
        refetchOnWindowFocus: false,
    });
}

function useAgentSelfQuery(api: APIContextValue, role: Role) {
    return useQuery({
        queryKey: [...selfQueryKey(), "agent"],
        queryFn: () => api.agentClient.GET("/self"),
        staleTime: Infinity,
        enabled: role === Role.AGENT,
        refetchOnWindowFocus: false,
    });
}

export const UserContextProvider = memo((props: PropsWithChildren) => {
    const ctx = useLoadingContext();
    const ctxRef = useLatestRef(ctx);

    const api = useAPI();
    const roleCtx = useRoleContext();

    const publicSelfQuery = usePublicSelfQuery(api, roleCtx.role);
    const agentSelfQuery = useAgentSelfQuery(api, roleCtx.role);

    useEffect(() => {
        if (!publicSelfQuery.isLoading && !agentSelfQuery.isLoading) {
            ctxRef.current.removeLoading(authzLoadingKey);
        }
    }, [publicSelfQuery.isLoading, agentSelfQuery.isLoading, ctxRef]);

    const self = publicSelfQuery.data?.data || agentSelfQuery.data?.data;

    if (publicSelfQuery.isLoading || agentSelfQuery.isLoading)
        return <div>Loading self...</div>;
    if (!self) return <div>Couldn&apos;t load self</div>;

    return (
        <UserContext.Provider value={self}>
            {props.children}
        </UserContext.Provider>
    );
});
UserContextProvider.displayName = "UserContextProvider";
