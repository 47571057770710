import type { FC } from "react";

import type { Promisable } from "type-fest";

import {
    Box,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from "@chakra-ui/react";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import type { User } from "@app/contexts/UserContext.tsx";
import { useUserContext } from "@app/contexts/useUserContext.tsx";
import type { OrganizationModel } from "@app/domain/api/organization.ts";

import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable } from "@mt-components/DataTable.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

import { Icon } from "@mt-design/icons.tsx";

import { useRoleContext } from "src/App/contexts/RoleContext";
import { Role } from "src/App/domain/auth";

type Props = {
    items: OrganizationModel[];
    onGoToOrg: (orgId: string) => void;
    onUpdate: (data: OrganizationModel) => void;
    onDelete: (id: string) => Promisable<unknown>;
};

interface TableMeta {
    user: User;
    role: Role;
    organizations: OrganizationModel[];
    onDelete: (id: string) => Promisable<unknown>;
    currentOrg: string;
    onSelect: (id: string) => Promisable<unknown>;
    onUpdate: (data: OrganizationModel) => Promisable<unknown>;
}

const columns: ColumnDef<OrganizationModel>[] = [
    {
        accessorKey: "name",
        header: "Name",
        cell: (cell) => {
            const meta = cell.table.options.meta as TableMeta;
            return (
                <>
                    {cell.cell.row.original.name}{" "}
                    <Box as="span" fontSize="14px" fontStyle="italic">
                        {" "}
                        {meta.currentOrg === cell.cell.row.original.id
                            ? "  active"
                            : null}
                    </Box>
                </>
            );
        },
    },
    {
        accessorKey: "createdAt",
        header: "Created",
        cell: (cell) => {
            return formatDate(cell.row.original.createdAt);
        },
    },
    {
        accessorKey: "menu",
        header: " ",

        // @ts-ignore
        thProps: {
            textAlign: "end",
            pr: "40px",
        },
        cell: (cell) => {
            const value = cell.row.original.id;
            const meta = cell.table.options.meta as TableMeta;
            return meta.role === Role.AGENT ? null : (
                <Menu>
                    <MenuButton
                        bg="white"
                        aria-label="Menu"
                        colorScheme="gray"
                        as={IconButton}
                        icon={<Icon.Menu />}
                    />
                    <MenuList>
                        <MenuItem onClick={() => meta.onSelect(value)}>
                            Activate
                        </MenuItem>
                        <MenuItem
                            onClick={() => meta.onUpdate(cell.row.original)}
                        >
                            Edit
                        </MenuItem>
                    </MenuList>
                </Menu>
            );
        },
    },
];

export const OrganizationsTable: FC<Props> = ({
    items,
    onUpdate,
    onDelete,
    onGoToOrg,
}) => {
    const user = useUserContext();
    const roleCtx = useRoleContext();
    const orgContext = useOrgContext();

    const context: TableMeta = {
        onDelete,
        onUpdate,
        onSelect: orgContext.setCurrentOrg,
        currentOrg: orgContext.currentOrg,
        user: user,
        organizations: items,
        role: roleCtx.role,
    };

    return (
        <Box
            h="100%"
            overflow="hidden"
            w="100%"
            overflowX="auto"
            overflowY="auto"
        >
            <Box width="100%" h="100%">
                <DataTable
                    onRowClick={(org) => onGoToOrg(org.id)}
                    initialState={{
                        sorting: [
                            {
                                id: "createdAt",
                                desc: true,
                            },
                        ],
                    }}
                    variant="unstyled"
                    columns={columns}
                    data={items}
                    isSortable
                    meta={context}
                />
            </Box>
        </Box>
    );
};

OrganizationsTable.displayName = "OrganizationsTable";
