import type { FC } from "react";
import { useMemo, useState } from "react";

import { equals, flatten, isNotNil } from "ramda";

import {
    Avatar,
    AvatarGroup,
    Box,
    chakra,
    HStack,
    IconButton,
    Input,
    Spinner,
    Tag,
    Text,
    Textarea,
    Tooltip,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { Link, useNavigate, useRouterState } from "@tanstack/react-router";

import { LocalStorageKeys } from "@app/config.ts";
import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import type {
    AdminMeasurementGroupModel,
    MeasurementGroupModel,
    MeasurementGroupStatusType,
} from "@app/domain";
import { MeasurementGroupStatus } from "@app/domain";
import type { BeamtimeModel } from "@app/domain/api/agent/beamtime.ts";
import { Identity } from "@app/domain/api/agent/identity.ts";
import type { MeasurementTaskModel } from "@app/domain/api/measurementTask.ts";
import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import { Organization } from "@app/domain/api/organization.ts";
import type { PackageModel } from "@app/domain/api/package.ts";
import { Package } from "@app/domain/api/package.ts";
import { Request } from "@app/domain/api/request.ts";
import type {
    CreateSamplePayload,
    SampleModel,
} from "@app/domain/api/sample.ts";
import { Sample } from "@app/domain/api/sample.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { mergeSamplesWithTasks } from "@app/domain/api/transformer.tsx";
import { agentSampleTableSpec } from "@app/domain/services/sample/ioMapping.ts";
import { SelectTasksFromSampleTasks } from "@app/pages/agent/requestDetails/SelectTasksFromSampleTasks.tsx";
import { PackageModal } from "@app/pages/user/packages/Package.modal.tsx";
import { routes } from "@app/Routes/routes.ts";
import { GroupStatus } from "@app-components/measurementGroup/GroupStatus/GroupStatus.tsx";
import { SampleTaskSidebar } from "@app-components/measurementGroup/SampleTaskSidebar/SampleTaskSidebar.tsx";
import { SampleTaskTable } from "@app-components/measurementTask/SampleTask.table.tsx";
import { MeasurementTaskModal } from "@app-components/modals/measurement/MeasurementTask.modal.tsx";
import { UpdateMeasurementTaskBatchModal } from "@app-components/modals/measurement/UpdateMeasurementTask.batch.modal.tsx";
import { UploadMeasurementGroupReportModal } from "@app-components/modals/measurement/UploadReport.modal.tsx";
import { QRCodeUpdateModal } from "@app-components/modals/sample/QRCodeUpdate.modal.tsx";
import { SampleModal } from "@app-components/modals/sample/Sample.modal.tsx";
import { UploadAuxFileModal } from "@app-components/modals/UploadAuxFileModal.tsx";
import { Packages } from "@app-components/package/Packages.tsx";

import type { ToggleItem } from "@mt-components/Button/ToggleButtonBar.tsx";
import { ToggleButtonBar } from "@mt-components/Button/ToggleButtonBar.tsx";
import type { Crumb } from "@mt-components/Crumbs/Crumbs.tsx";
import {
    Property,
    PropertyLabel,
    PropertyList,
    PropertyValue,
} from "@mt-components/Input/Property";
import { SingleSelect } from "@mt-components/Input/Select/Select.tsx";
import { Actions } from "@mt-components/Layout/Actions.tsx";
import { Divider } from "@mt-components/Layout/Divider.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";
import { QrReader } from "@mt-components/QrReader/QrReader.tsx";

import { useConfirm } from "@mt-hooks/useConfirm.tsx";
import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";
import { onEnter } from "@mt-tools/interaction.ts";
import {
    downloadArrayAsCSV,
    downloadFetchBlob,
    formatDataForDownload,
} from "@mt-tools/io/downloadFile.ts";
import { byId, getById } from "@mt-tools/iterating/filter.ts";

import { Icon } from "@mt-design/icons.tsx";

import { DeleteReportModal } from "src/App/components/modals/measurement/DeleteReport.modal";
import { OrganizationLink } from "src/App/components/OrganizationLink";
import { useDisclosureWithStorage } from "src/packages/hooks/useDisclosureWithStorage.ts";

import { useReportTemplateDownloader } from "./ReportTemplateDownloader";

const statusOptions = [
    {
        value: MeasurementGroupStatus.Draft,
        label: "Draft",
        rawLabel: "Draft",
    },
    {
        value: MeasurementGroupStatus.Submitted,
        label: "Submitted",
        rawLabel: "Submitted",
    },
    {
        value: MeasurementGroupStatus.Accepted,
        label: "Accepted",
        rawLabel: "Accepted",
    },
    {
        value: MeasurementGroupStatus.Scheduled,
        label: "Scheduled",
        rawLabel: "Scheduled",
    },
    {
        value: MeasurementGroupStatus.Completed,
        label: "Completed",
        rawLabel: "Completed",
    },
    {
        value: MeasurementGroupStatus.Declined,
        label: "Rejected",
        rawLabel: "Rejected",
    },
];

const ActionID = {
    BATCH_ASSIGN_QR_CODES: "batch-assign-qr-codes",
    SAMPLES: "samples",
    SAMPLES_CREATE: "samples-create",
    MOVE_SAMPLES: "move-samples",
    BATCH_UPDATE_SAMPLES: "batch-update-samples",
    UPLOAD_AUX: "upload-aux",
    REMOVE_SAMPLES: "remove-samples",
    DOWNLOAD_AUX: "download-aux",
    DOWNLOAD_SAMPLES: "download-samples",
    INSPECT_RESULTS: "inspect-results",
    AUX_FILES: "aux-files",
    REPORT: "report",
    DOWNLOAD_REPORT: "download-report",
    DOWNLOAD_REPORT_TEMPLATE: "download-report-template",
    UPLOAD_REPORT: "upload-report",
    DELETE_REPORT: "delete-report",
};

type RequestDetailProps = {
    measurementGroup: AdminMeasurementGroupModel;
    beamtimes: BeamtimeModel[];
    samples: SampleModel[];
    tasksOfGroup: MeasurementTaskModel[];
    deleteTasks: (taskId: string[]) => Promise<void>;
};

const toggleButtonBar: ToggleItem<"wide" | "narrow">[] = [
    {
        id: "wide" as const,
        label: "Wide table",
        icon: <Icon.FourColumn />,
        ariaLabel: "expand table",
    },
    {
        id: "narrow" as const,
        label: "Narrow table",
        icon: <Icon.TwoColumn />,
        ariaLabel: "collapse table",
    },
];

const linkStyle = {
    display: "block",
    width: "100%",
    height: "100%",
};

export const RequestDetails: FC<RequestDetailProps> = ({
    measurementGroup,
    tasksOfGroup,
    samples,
    beamtimes,
    deleteTasks,
}) => {
    const snackbar = useSnackbar();
    const updateSample = Sample.useUpdate();
    const api = useAPI();

    const watchers = Identity.useUsers(measurementGroup.watchList);
    const navigate = useNavigate();
    const uploadFileDisclosure = useDisclosure();
    const batchTaskUpdateDisclosure = useDisclosure();
    const assignQRCodeDisclosure = useDisclosure();
    const uploadReportDisclosure = useDisclosure();
    const deleteReportDisclosure = useDisclosure();
    const measurementTasksDisclosure = useDisclosure();
    const moveTasksDisclosure = useDisclosure();

    const [packageToView, setPackageToView] = useState<
        PackageModel | undefined
    >();

    const confirmDelete = useConfirm();

    const [toEdit, setToEdit] = useState<SampleTasksModel | undefined>(
        undefined,
    );

    const createTasks = MeasurementTask.useCreate();
    const [qrCodeToEdit, setQrCodeToEdit] = useState<
        SampleTasksModel | undefined
    >(undefined);

    const [buttonOption, setButtonOption] = useState<"wide" | "narrow">(
        "narrow",
    );

    const sidebarDisclosure = useDisclosureWithStorage(
        LocalStorageKeys.agent.measurementGroupSampleTaskSidebar,
    );
    const router = useRouterState();

    const [selectedSampleIds, setSelectedSampleIDs] = useState<string[]>([]);
    const [beamtime_id, setBeamtimeId] = useState<string | undefined>(
        measurementGroup.beamtimeId ?? undefined,
    );
    const sampleTasks = mergeSamplesWithTasks(samples, tasksOfGroup);
    const sampleTasksById = byId(sampleTasks);

    const selectedSampleTasks = selectedSampleIds
        .map((id) => sampleTasksById[id])
        .filter(isNotNil);

    const { mutateAsync: update } = Request.useAdminUpdate();

    const beamtimeOptions = useMemo(() => {
        const options = beamtimes.map((b) => ({
            value: b.id,
            label: b.date
                ? new Date(b.date).toDateString() +
                  " - " +
                  b.name +
                  " - " +
                  b.status
                : "unscheduled",
            rawLabel: b.date
                ? new Date(b.date).toDateString() +
                  " - " +
                  b.name +
                  " - " +
                  b.status
                : "unscheduled",
        }));
        return [
            ...options,
            {
                value: "unscheduled",
                label: "unscheduled",
                rawLabel: "unscheduled",
            },
        ];
    }, [beamtimes]);

    const onUpdate = async (beamtimeId: string | null) => {
        await update({
            measurementGroupId: measurementGroup.id,
            organizationId: measurementGroup.organizationId,
            patchMeasurementGroupPayload: {
                beamtimeId,
            },
        });
    };

    const onChangeStatus = async (newStatus: MeasurementGroupStatusType) => {
        await update({
            measurementGroupId: measurementGroup.id,
            organizationId: measurementGroup.organizationId,
            patchMeasurementGroupPayload: {
                status: newStatus,
            },
        });
    };

    const onChangeBeamtime = (beamtimeId: string | undefined) => {
        void setBeamtimeId(beamtimeId);
        // @todo removing beamtime isn't possible.
        void onUpdate(beamtimeId ?? null);
    };

    const onUpdateSample = async (data: CreateSamplePayload) => {
        if (!toEdit) {
            return;
        }

        await updateSample.mutateAsync({
            params: {
                path: {
                    sampleId: toEdit.id,
                    organizationId: toEdit.organizationId,
                },
            },
            body: data,
        });
    };

    const goToSample = (sampleId: string) =>
        navigate({
            from: router.location.pathname,
            to: routes.agent.samples.url,
            search: {
                samples: [sampleId],
            },
        });

    const { mutateAsync: downloadAuxFiles } = Request.useDownloadAuxFiles();
    const { mutateAsync: deleteAuxFiles } = Request.useDeleteAuxFiles();
    const organizationQuery = Organization.useGet({
        path: { organizationId: measurementGroup.organizationId },
    });

    const packagesQuery = Package.useGetAll({
        params: {
            path: {
                organizationId: measurementGroup.organizationId,
            },
            query: {
                filter: {
                    measurementGroupId: measurementGroup.id,
                },
            },
        },
    });

    const onDownloadRepot = async () => {
        await downloadReport(measurementGroup);
    };

    const onDeleteAuxFile = (fileNames: string[]) => {
        confirmDelete({
            onConfirm: () =>
                deleteAuxFiles({
                    measurementGroupId: measurementGroup.id,
                    organizationId: measurementGroup.organizationId,
                    fileNames: fileNames,
                }),
            title: "Remove Auxiliary Files",
            body: (
                <Text>
                    Are you sure you want to remove the auxiliary file{" "}
                    <Text as="span" textStyle="T1" fontWeight="700">
                        {fileNames[0]}
                    </Text>{" "}
                    from the request
                </Text>
            ),
            onCancel: () => {},
        });
    };

    const onUseQRCode = async (qrCode: string) => {
        if (!qrCodeToEdit) {
            return;
        }

        await updateSample
            .mutateAsync({
                params: {
                    path: {
                        sampleId: qrCodeToEdit.id,
                        organizationId: qrCodeToEdit.organizationId,
                    },
                },
                body: {
                    qrCode: qrCode,
                },
            })
            .then((r) => {
                if (!r.response.ok) {
                    snackbar.error("Updating QR Code failed.");
                }
            });
        setQrCodeToEdit(undefined);
    };

    const downloadReportTemplate = useReportTemplateDownloader(
        measurementGroup.organizationId,
        measurementGroup.id,
    );

    const downloadReport = async (model: MeasurementGroupModel) => {
        const res = await api.client.GET(
            "/organizations/{organizationId}/measurement-groups/{measurementGroupId}/report",
            {
                params: {
                    path: {
                        organizationId: model.organizationId,
                        measurementGroupId: model.id,
                    },
                },
                parseAs: "blob",
            },
        );

        downloadFetchBlob(res);
    };

    const [quotationNumber, setQuotationNumber] = useState(
        measurementGroup.quotationNumber,
    );

    const updateQuotationNumberMutation = Request.useAdminUpdate();
    const onUpdateQuotationNumber = () => {
        if (equals(measurementGroup.quotationNumber, quotationNumber?.trim())) {
            return;
        }

        if (!quotationNumber || quotationNumber.length <= 5) {
            snackbar.error({
                title: "Quotation could not be updated",
                description: "Min length 5 characters",
            });
            setQuotationNumber(measurementGroup.quotationNumber);
            return;
        }
        updateQuotationNumberMutation
            .mutateAsync({
                measurementGroupId: measurementGroup.id,
                organizationId: measurementGroup.organizationId,
                patchMeasurementGroupPayload: {
                    quotationNumber: quotationNumber,
                },
            })
            .then((r) => {
                if (r.response.ok) {
                    snackbar.success({
                        title: "Quotation number updated",
                    });
                }
                if (!r.response.ok) {
                    snackbar.error({
                        title: "Quotation could not be updated",
                    });
                    setQuotationNumber(measurementGroup.quotationNumber);
                }
            })
            .catch(() => {
                snackbar.error({
                    title: "Quotation could not be updated",
                });
            });
    };

    const [internalNote, setInternalNote] = useState(
        measurementGroup.internalNote,
    );
    const updateInternalNoteMutation = Request.useAdminUpdate();

    const crumbs: Crumb[] = [
        {
            id: "req",
            label: (
                <Link
                    to={routes.agent.measurementGroups.url}
                    params={{
                        orgId: measurementGroup.organizationId,
                    }}
                >
                    <Text textStyle="hx">Requests</Text>
                </Link>
            ),
        },
        {
            id: "details",
            label: (
                <Link
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    to={routes.agent.measurementGroupDetail.url}
                    params={{
                        orgId: measurementGroup.organizationId,
                        orderId: measurementGroup.id,
                    }}
                >
                    <GroupStatus status={measurementGroup.status} />
                    <Text ml="8px" display="inline">
                        {measurementGroup.name}
                    </Text>
                </Link>
            ),
        },
    ];

    const preferedDate = beamtimes.find(
        getById(measurementGroup.preferredBeamtimeId),
    )?.date;

    const beamtime = beamtime_id
        ? beamtimes.find(getById(beamtime_id))
        : undefined;

    const selectedStatusOption = statusOptions.find(
        (o) => o.value === measurementGroup.status,
    );

    return (
        <>
            {Boolean(packageToView) && (
                <PackageModal
                    isOpen={Boolean(packageToView)}
                    canDelete
                    canEdit={
                        packageToView?.status === "draft"
                            ? ["status" as const]
                            : []
                    }
                    statusOptions={["draft", "dispatched", "lost", "received"]}
                    initialData={packageToView}
                    onCancel={() => setPackageToView(undefined)}
                    requestId={measurementGroup.id}
                    orgId={measurementGroup.organizationId}
                />
            )}

            {measurementTasksDisclosure.isOpen && (
                <MeasurementTaskModal
                    orgId={measurementGroup.organizationId}
                    isOpen={measurementTasksDisclosure.isOpen}
                    onCancel={measurementTasksDisclosure.onClose}
                    onSubmit={async (params) => {
                        await createTasks.mutateAsync({
                            params: {
                                path: {
                                    organizationId:
                                        measurementGroup.organizationId,
                                },
                            },
                            payload: {
                                measurementGroupId: measurementGroup.id,
                                sampleId: params.sampleId,
                                method: params.method,
                            },
                        });
                    }}
                    onClose={measurementTasksDisclosure.onClose}
                    existingMeasurementTasks={tasksOfGroup}
                    samples={samples}
                />
            )}

            {batchTaskUpdateDisclosure.isOpen && (
                <UpdateMeasurementTaskBatchModal
                    requestId={measurementGroup.id}
                    orgId={measurementGroup.organizationId}
                    isOpen={batchTaskUpdateDisclosure.isOpen}
                    sampleTasks={selectedSampleTasks}
                    onClose={batchTaskUpdateDisclosure.onClose}
                />
            )}
            {moveTasksDisclosure.isOpen && (
                <SelectTasksFromSampleTasks
                    sourceRequestId={measurementGroup.id}
                    orgId={measurementGroup.organizationId}
                    isOpen={moveTasksDisclosure.isOpen}
                    onClose={moveTasksDisclosure.onClose}
                    sampleTasksToMove={selectedSampleTasks}
                />
            )}
            {uploadReportDisclosure.isOpen ? (
                <UploadMeasurementGroupReportModal
                    model={measurementGroup}
                    onClose={uploadReportDisclosure.onClose}
                    onUploaded={(isOk) => {
                        uploadReportDisclosure.onClose();
                        if (isOk) {
                            snackbar.success(
                                `Report for ${measurementGroup.name} uploaded`,
                            );
                        } else {
                            snackbar.error(
                                `Error while uploading report for ${measurementGroup.name}`,
                            );
                        }
                    }}
                />
            ) : null}

            {deleteReportDisclosure.isOpen ? (
                <DeleteReportModal
                    model={measurementGroup}
                    onClose={deleteReportDisclosure.onClose}
                    onDeleted={(isOk) => {
                        deleteReportDisclosure.onClose();
                        if (isOk) {
                            snackbar.success(
                                `Report for ${measurementGroup.name} deleted`,
                            );
                        } else {
                            snackbar.error(
                                `Error while deleting report for ${measurementGroup.name}`,
                            );
                        }
                    }}
                />
            ) : null}

            {qrCodeToEdit && (
                <Modal
                    header="Set QR code"
                    isOpen={Boolean(qrCodeToEdit)}
                    onClose={() => setQrCodeToEdit(undefined)}
                >
                    <QrReader onResult={onUseQRCode} />
                </Modal>
            )}

            {assignQRCodeDisclosure.isOpen && (
                <QRCodeUpdateModal
                    isOpen={assignQRCodeDisclosure.isOpen}
                    organizationId={measurementGroup.organizationId}
                    onToggle={assignQRCodeDisclosure.onToggle}
                    id={measurementGroup.id}
                />
            )}

            {uploadFileDisclosure.isOpen && (
                <UploadAuxFileModal
                    isOpen={uploadFileDisclosure.isOpen}
                    onClose={uploadFileDisclosure.onClose}
                    measurementRequests={[measurementGroup]}
                />
            )}
            {toEdit && (
                <SampleModal
                    isOpen={Boolean(toEdit)}
                    onSubmit={onUpdateSample}
                    sample={toEdit}
                    onClose={() => setToEdit(undefined)}
                />
            )}
            <HStack id="measurement-group-details-main" mx="auto" h="100%">
                <PageLayout
                    display="flex"
                    flexDirection="column"
                    id="center-box"
                    flex="1 0 350px"
                    overflow="auto"
                    pr="0"
                >
                    <PageHeader crumbs={crumbs} />
                    <VStack flex={1} alignItems="stretch" overflowY="auto">
                        <PropertyList fontSize="14px" gap="12px">
                            <Property key="organization">
                                <PropertyLabel width="160px">
                                    Organization
                                </PropertyLabel>
                                <PropertyValue>
                                    {organizationQuery.data?.data ? (
                                        <OrganizationLink
                                            organizationId={
                                                organizationQuery.data.data.id
                                            }
                                            label={
                                                organizationQuery.data.data.name
                                            }
                                        ></OrganizationLink>
                                    ) : null}
                                </PropertyValue>
                            </Property>
                            <Property key="watcher">
                                <PropertyLabel width="160px">
                                    Watcher
                                </PropertyLabel>
                                <PropertyValue>
                                    <AvatarGroup size="sm" max={8}>
                                        {watchers.data?.map((watcher) => {
                                            return (
                                                <Avatar
                                                    key={watcher.id}
                                                    position="relative"
                                                    name={watcher.name}
                                                    src={watcher.avatarUrl}
                                                >
                                                    <Box
                                                        position="absolute"
                                                        top={0}
                                                        left={0}
                                                        borderRadius="100%"
                                                        h="100%"
                                                        w="100%"
                                                    >
                                                        <Tooltip
                                                            label={watcher.name}
                                                        >
                                                            <Link
                                                                style={
                                                                    linkStyle
                                                                }
                                                                to="/agent/users/$userId"
                                                                params={{
                                                                    userId: watcher.id,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                </Avatar>
                                            );
                                        })}
                                    </AvatarGroup>
                                </PropertyValue>
                            </Property>
                            <Property key="quotation">
                                <PropertyLabel width="160px">
                                    Quotation
                                </PropertyLabel>
                                <PropertyValue
                                    maxW="420px"
                                    color="gray.800"
                                    fontSize="14px"
                                    justifyContent="flex-start"
                                >
                                    <HStack>
                                        <Input
                                            w="420px"
                                            onChange={(e) =>
                                                setQuotationNumber(
                                                    e.currentTarget.value,
                                                )
                                            }
                                            onBlur={onUpdateQuotationNumber}
                                            value={quotationNumber || ""}
                                        />
                                        {updateQuotationNumberMutation.isPending ? (
                                            <Spinner />
                                        ) : null}
                                    </HStack>
                                </PropertyValue>
                            </Property>

                            <Property key="preferred-beamtime">
                                <PropertyLabel width="160px">
                                    Customer&apos;s preferred beamtime
                                </PropertyLabel>
                                <PropertyValue maxW="420px" px="4px">
                                    <Box w="100%">
                                        {preferedDate
                                            ? formatDate(preferedDate)
                                            : null}
                                    </Box>
                                </PropertyValue>
                            </Property>

                            <Property key="beamtime">
                                <PropertyLabel width="160px">
                                    Beamtime
                                </PropertyLabel>
                                <PropertyValue maxW="420px">
                                    <Box w="100%">
                                        <SingleSelect
                                            isDisabled={
                                                measurementGroup.status ===
                                                MeasurementGroupStatus.Completed
                                            }
                                            value={
                                                beamtime
                                                    ? {
                                                          value: beamtime.id,
                                                          label: beamtime.name,
                                                          rawLabel:
                                                              beamtime.name,
                                                      }
                                                    : undefined
                                            }
                                            placeholder="Schedule to beamtime"
                                            onChange={(newValue) =>
                                                onChangeBeamtime(
                                                    newValue?.value,
                                                )
                                            }
                                            options={beamtimeOptions}
                                        ></SingleSelect>
                                    </Box>
                                </PropertyValue>
                            </Property>
                            <Property key="status">
                                <PropertyLabel width="160px">
                                    Status
                                </PropertyLabel>
                                <PropertyValue maxW="420px">
                                    <Box w="100%">
                                        <SingleSelect
                                            isClearable={false}
                                            value={selectedStatusOption}
                                            onChange={(newValue) =>
                                                newValue?.value
                                                    ? onChangeStatus(
                                                          newValue.value,
                                                      )
                                                    : null
                                            }
                                            options={statusOptions}
                                            placeholder="Change status"
                                        />
                                    </Box>
                                </PropertyValue>
                            </Property>
                            <Property key="report">
                                <PropertyLabel width="160px">
                                    Report
                                </PropertyLabel>
                                <PropertyValue maxW="420px">
                                    {measurementGroup.hasReport ? (
                                        <Tag
                                            tabIndex={0}
                                            aria-label="download-report"
                                            cursor="pointer"
                                            onKeyUp={onEnter(onDownloadRepot)}
                                            onClick={() =>
                                                downloadReport(measurementGroup)
                                            }
                                            colorScheme="green"
                                            gap="4px"
                                            pr={0}
                                        >
                                            <Icon.File size="16px" />
                                            <Tooltip
                                                label="Report.pdf"
                                                openDelay={1000}
                                            >
                                                <chakra.span
                                                    ml="8px"
                                                    overflow="hidden"
                                                    textOverflow="clip"
                                                    whiteSpace="nowrap"
                                                >
                                                    Report.pdf
                                                </chakra.span>
                                            </Tooltip>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteReportDisclosure.onOpen();
                                                }}
                                                color="currentColor"
                                                bg="unset"
                                                transition="color"
                                                _hover={{
                                                    color: "green.500",
                                                    bg: "unset",
                                                }}
                                                size="xs"
                                                icon={<Icon.Close />}
                                                aria-label="remove report"
                                            />
                                        </Tag>
                                    ) : (
                                        <Box color="red.500">
                                            <Icon.Cross />
                                        </Box>
                                    )}
                                </PropertyValue>
                            </Property>
                            <Property
                                maxHeight="180px"
                                key="message"
                                alignItems="flex-start"
                            >
                                <PropertyLabel width="160px">
                                    Message
                                </PropertyLabel>
                                <PropertyValue
                                    overflow="hidden"
                                    wordBreak="break-all"
                                >
                                    {measurementGroup.message}
                                </PropertyValue>
                            </Property>
                            <Property
                                maxHeight="180px"
                                key="shipments"
                                alignItems="center"
                            >
                                <PropertyLabel width="160px">
                                    <HStack gap="8px">
                                        <span>Shipments</span>
                                        <Tooltip label="Add tracking information about your shipped samples here">
                                            <Icon.Info size="14px" />
                                        </Tooltip>
                                    </HStack>
                                </PropertyLabel>
                                <PropertyValue
                                    overflow="hidden"
                                    wordBreak="break-all"
                                    pl="4px"
                                >
                                    <HStack gap="12px">
                                        <Packages
                                            onClick={(paket) => {
                                                setPackageToView(paket);
                                            }}
                                            pakets={packagesQuery.data || []}
                                        />
                                    </HStack>
                                </PropertyValue>
                            </Property>
                            <Property key="aux-files">
                                <PropertyLabel width="160px">
                                    Auxiliary Files
                                </PropertyLabel>
                                <PropertyValue>
                                    <HStack
                                        flexWrap="wrap"
                                        w="100%"
                                        justifyContent="flex-start"
                                    >
                                        {measurementGroup.files.map((f) => (
                                            <Box
                                                key={f}
                                                my="4px"
                                                mr="12px"
                                                display="inline-flex"
                                            >
                                                <Tag
                                                    colorScheme="purple"
                                                    gap="4px"
                                                    pr={0}
                                                >
                                                    <Icon.File size="16px" />
                                                    <Tooltip
                                                        label={f}
                                                        openDelay={1000}
                                                    >
                                                        <chakra.span
                                                            ml="8px"
                                                            maxW="180px"
                                                            overflow="hidden"
                                                            textOverflow="clip"
                                                            whiteSpace="nowrap"
                                                        >
                                                            {f}
                                                        </chakra.span>
                                                    </Tooltip>
                                                    <IconButton
                                                        onClick={() =>
                                                            onDeleteAuxFile([f])
                                                        }
                                                        color="currentColor"
                                                        bg="unset"
                                                        transition="color"
                                                        _hover={{
                                                            color: "purple.500",
                                                            bg: "unset",
                                                        }}
                                                        size="xs"
                                                        icon={<Icon.Close />}
                                                        aria-label="remove file"
                                                    />
                                                </Tag>
                                            </Box>
                                        ))}
                                    </HStack>
                                </PropertyValue>
                            </Property>
                            <Property
                                maxHeight="180px"
                                key="internal-note"
                                alignItems="flex-start"
                            >
                                <PropertyLabel width="160px">
                                    Internal note
                                </PropertyLabel>
                                <PropertyValue wordBreak="break-all">
                                    <HStack>
                                        <Textarea
                                            w="420px"
                                            onChange={(e) =>
                                                setInternalNote(
                                                    e.currentTarget.value,
                                                )
                                            }
                                            onBlur={() => {
                                                if (
                                                    equals(
                                                        internalNote?.trim(),
                                                        measurementGroup.internalNote,
                                                    )
                                                ) {
                                                    return;
                                                }

                                                updateInternalNoteMutation
                                                    .mutateAsync({
                                                        organizationId:
                                                            measurementGroup.organizationId,
                                                        measurementGroupId:
                                                            measurementGroup.id,
                                                        patchMeasurementGroupPayload:
                                                            {
                                                                internalNote:
                                                                    internalNote?.trim(),
                                                            },
                                                    })
                                                    .catch(() => {
                                                        snackbar.error({
                                                            title: "Internal note could not be updated",
                                                        });
                                                        setInternalNote(
                                                            measurementGroup.internalNote,
                                                        );
                                                    });
                                            }}
                                            value={internalNote}
                                        />
                                        {updateInternalNoteMutation.isPending ? (
                                            <Spinner />
                                        ) : null}
                                    </HStack>
                                </PropertyValue>
                            </Property>
                        </PropertyList>

                        <Actions
                            actions={[
                                {
                                    id: ActionID.SAMPLES,
                                    label: "Samples",
                                    isAction: true,
                                    onClick: () => {},
                                    items: [
                                        {
                                            id: ActionID.REMOVE_SAMPLES,
                                            isDisabled: true,
                                            label: "Remove samples",
                                            onClick: async () => {
                                                const tasks = flatten(
                                                    selectedSampleTasks.map(
                                                        (s) =>
                                                            Object.values(
                                                                s.tasks,
                                                            ).map(
                                                                (t) => t.taskId,
                                                            ),
                                                    ),
                                                );
                                                await deleteTasks(tasks);
                                            },
                                        },
                                        {
                                            id: ActionID.MOVE_SAMPLES,
                                            label: "Move to other request",
                                            isDisabled:
                                                !selectedSampleTasks.length,
                                            onClick: moveTasksDisclosure.onOpen,
                                        },
                                        {
                                            id: ActionID.BATCH_UPDATE_SAMPLES,
                                            label: "Update methods",
                                            isDisabled:
                                                !selectedSampleTasks.length,
                                            onClick:
                                                batchTaskUpdateDisclosure.onOpen,
                                        },
                                        {
                                            id: ActionID.SAMPLES_CREATE,
                                            label: "Add samples",
                                            onClick:
                                                measurementTasksDisclosure.onOpen,
                                        },
                                        {
                                            id: ActionID.BATCH_ASSIGN_QR_CODES,
                                            label: "Assign QR Codes",
                                            onClick:
                                                assignQRCodeDisclosure.onOpen,
                                        },
                                        {
                                            id: ActionID.DOWNLOAD_SAMPLES,
                                            label: "Download samples",
                                            onClick: () => {
                                                const downloadable =
                                                    formatDataForDownload(
                                                        agentSampleTableSpec,
                                                        sampleTasks,
                                                    );
                                                downloadArrayAsCSV(
                                                    downloadable.data,
                                                    downloadable.keys,
                                                    "sample-list.csv",
                                                );
                                            },
                                        },
                                    ],
                                },
                                {
                                    id: ActionID.AUX_FILES,
                                    label: "Aux files",
                                    isAction: true,
                                    onClick: () => {},
                                    items: [
                                        {
                                            id: ActionID.DOWNLOAD_AUX,
                                            label: "Download files",
                                            isDisabled:
                                                !measurementGroup.files.length,
                                            onClick: () =>
                                                downloadAuxFiles({
                                                    measurementGroupId:
                                                        measurementGroup.id,
                                                    organizationId:
                                                        measurementGroup.organizationId,
                                                }),
                                        },
                                        {
                                            id: ActionID.UPLOAD_AUX,
                                            label: "Upload files",
                                            onClick:
                                                uploadFileDisclosure.onOpen,
                                        },
                                    ],
                                },

                                {
                                    id: ActionID.REPORT,
                                    label: "Report",
                                    onClick: () => {},
                                    isAction: true,
                                    items: [
                                        {
                                            id: ActionID.DOWNLOAD_REPORT_TEMPLATE,
                                            label: "Download template",
                                            onClick: downloadReportTemplate,
                                        },
                                        {
                                            id: ActionID.DOWNLOAD_REPORT,
                                            label: "Download report",
                                            isDisabled:
                                                !measurementGroup.hasReport,
                                            onClick: () =>
                                                downloadReport(
                                                    measurementGroup,
                                                ),
                                        },
                                        {
                                            id: ActionID.UPLOAD_REPORT,
                                            label: "Upload report",
                                            onClick: () =>
                                                uploadReportDisclosure.onOpen(),
                                        },
                                        {
                                            id: ActionID.DELETE_REPORT,
                                            label: "Delete report",
                                            onClick: () => {
                                                deleteReportDisclosure.onOpen();
                                            },
                                            isDisabled:
                                                !measurementGroup.hasReport,
                                        },
                                    ],
                                },
                                {
                                    id: ActionID.INSPECT_RESULTS,
                                    label: "Inspect results",
                                    isPrimary: true,
                                    onClick: () =>
                                        sampleTasks[0].id &&
                                        navigate({
                                            to: routes.agent.requestResult.url,
                                            search: {
                                                sample: sampleTasks[0].id,
                                            },
                                            params: {
                                                orderId: measurementGroup.id,
                                                orgId: measurementGroup.organizationId,
                                            },
                                        }),
                                },
                            ]}
                        />
                        <Divider />
                        <Box>
                            <ToggleButtonBar<"wide" | "narrow">
                                onToggle={setButtonOption}
                                option={buttonOption}
                                items={toggleButtonBar}
                            />
                        </Box>
                        <Box mt="24px" h="100%" flex={1} w="100%">
                            <SampleTaskTable
                                tableStyle={{
                                    overflowY: "auto",
                                }}
                                isWide={buttonOption === "wide"}
                                onSelectSample={setSelectedSampleIDs}
                                selectedSampleTaskIds={selectedSampleIds}
                                sampleTasks={sampleTasks}
                            />
                        </Box>
                    </VStack>
                </PageLayout>
                <SampleTaskSidebar
                    canEdit
                    canViewSample
                    hideHazards={false}
                    onToggle={sidebarDisclosure.onToggle}
                    isOpen={Boolean(
                        sidebarDisclosure.isOpen && tasksOfGroup.length > 0,
                    )}
                    sampleTasks={selectedSampleTasks}
                    measurementGroup={measurementGroup}
                    onSelectToEdit={setToEdit}
                    goToSample={goToSample}
                    onOpenQRCodeReader={setQrCodeToEdit}
                />
            </HStack>
        </>
    );
};

RequestDetails.displayName = "RequestDetails";
