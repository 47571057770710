import type { FC } from "react";

import type { Promisable } from "type-fest";

import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tag,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { Link, useNavigate } from "@tanstack/react-router";

import { useUserContext } from "@app/contexts/useUserContext.tsx";
import { Authz } from "@app/domain/api/authz.ts";
import type {
    CreateOrganizationRequest,
    OrganizationModel,
} from "@app/domain/api/organization.ts";
import type { OrganizationRole } from "@app/domain/services/organization.ts";
import { OrganizationRoles } from "@app/domain/services/organization.ts";
import { routes } from "@app/Routes/routes.ts";
import { InviteUserModal } from "@app-components/modals/organization/InviteUser.modal.tsx";

import type { Crumb } from "@mt-components/Crumbs/Crumbs.tsx";
import { Crumbs } from "@mt-components/Crumbs/Crumbs.tsx";
import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable, makeWidth } from "@mt-components/DataTable.tsx";
import { SearchFilterBar } from "@mt-components/Input/SearchFilterBar.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { useSearch } from "@mt-hooks/useSearch.tsx";
import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { Icon } from "@mt-design/icons.tsx";

type Props = {
    isFormOpen: boolean;
    orgId: string;
    organization: OrganizationModel;
    onSubmit: (data: CreateOrganizationRequest) => Promise<void>;
    onToggleForm: () => void;
};

type User = {
    userId: string;
    email: string;
    role: OrganizationRole;
    avatarUrl?: string;
};

type TableMeta = {
    authRecord: { userId: string; role: OrganizationRole };
    onRemove: (userId: string) => Promisable<void>;
    onLeave: (userId: string) => Promisable<void>;
    members: { userId: string; role: OrganizationRole }[];
};

const columns: ColumnDef<User>[] = [
    {
        accessorKey: "email",
        header: "Email",
        cell: (cell) => {
            return <Box>{cell.cell.row.original.email}</Box>;
        },
    },
    {
        accessorKey: "role",
        header: "Role",
        cell: (ctx) => {
            if (ctx.cell.row.original.role === OrganizationRoles.OWNER) {
                return (
                    <Tag colorScheme="blue">{ctx.cell.row.original.role} </Tag>
                );
            }

            if (ctx.cell.row.original.role === OrganizationRoles.COLLABORATOR) {
                return (
                    <Tag colorScheme="purple">
                        {ctx.cell.row.original.role}{" "}
                    </Tag>
                );
            }

            return <Tag colorScheme="green">{ctx.cell.row.original.role} </Tag>;
        },
    },
    {
        id: "menu",
        header: "",
        thProps: makeWidth("80px"),
        cell: (ctx) => {
            const meta = ctx.table.options.meta as TableMeta;
            const numberOfOwners = meta.members.filter(
                ({ role }) => role === OrganizationRoles.OWNER,
            ).length;

            const actions = [
                {
                    id: "leve",
                    label: "Leave",
                    onClick: () => meta.onLeave(ctx.row.original.userId),
                    isHidden:
                        meta.authRecord.userId !== ctx.row.original.userId ||
                        numberOfOwners === 1,
                },
                {
                    id: "remove",
                    label: "Remove",
                    onClick: () => meta.onRemove(ctx.row.original.userId),
                    isHidden:
                        meta.authRecord.userId === ctx.row.original.userId ||
                        numberOfOwners === 1,
                },
            ].filter((a) => !a.isHidden);

            return (
                Boolean(actions.length) && (
                    <Menu>
                        <MenuButton
                            bg="white"
                            aria-label="Menu"
                            colorScheme="gray"
                            as={IconButton}
                            icon={<Icon.Menu />}
                        />
                        <MenuList>
                            {actions.map((a) => (
                                <MenuItem key={a.id} onClick={a.onClick}>
                                    {a.label}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                )
            );
        },
    },
];

// @todo: refactor and takeout queries and mutations

export const OrganizationDetail: FC<Props> = ({ organization, orgId }) => {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const user = useUserContext();
    const inviteDisclosure = useDisclosure();

    const orgAuthzQuery = Authz.useGetOrganizationRecords(orgId);
    const removeUserFromOrg = Authz.useRemoveUserFromOrg(orgId);

    const search = useSearch<User>({
        items: [
            ...(orgAuthzQuery.data || []),
            ...(orgAuthzQuery.data || []),
            ...(orgAuthzQuery.data || []),
            ...(orgAuthzQuery.data || []),
        ],
        keys: ["email", "role"],
    });

    const authRecord = orgAuthzQuery.data?.find(
        ({ userId }) => userId === user.id,
    );

    const onLeave = async (userId: string) => {
        void navigate({
            to: routes.user.home.url,
        });
        await removeUserFromOrg.mutateAsync({ userId }).then((r) => {
            if (!r.response.ok) {
                // @todo more specific error handling
                snackbar.error({
                    title: "An error occurred",
                    description:
                        "Member could not be removed from the organization",
                });
            }
        });
    };

    const meta = {
        authRecord,
        members: orgAuthzQuery.data ?? [],
        onRemove: async (userId: string) => {
            await removeUserFromOrg.mutateAsync({ userId });
        },
        onLeave,
    };

    const menu = authRecord?.role === OrganizationRoles.OWNER;

    const crumbs: Crumb[] = [
        {
            id: "organizations",
            label: (
                <Link to={routes.organizations.url}>
                    <Text textStyle="hx">Organizations</Text>
                </Link>
            ),
        },
        {
            id: "detail",
            label: organization.name,
            isCurrentPage: true,
        },
    ];

    return (
        <>
            {inviteDisclosure.isOpen && (
                <InviteUserModal
                    orgId={orgId}
                    isOpen={inviteDisclosure.isOpen}
                    onClose={inviteDisclosure.onToggle}
                />
            )}
            <PageLayout>
                <Box
                    overflow="hidden"
                    mx="auto"
                    display="flex"
                    flexDirection="column"
                    w="100%"
                    h="100%"
                >
                    <Box>
                        <Crumbs items={crumbs} />
                        <PageHeader
                            mt="12px"
                            title="Member Management"
                            subtitle="Manage your team members and their permissions here"
                            actions={[]}
                        />
                    </Box>
                    <Box
                        mb="14px"
                        display="flex"
                        flexWrap="wrap"
                        w="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box
                            as="div"
                            display="block"
                            textStyle="h5"
                            flex="0 0 240px"
                        >
                            <Text as="span">All members </Text>
                            <Text as="span" color="gray.400">
                                {orgAuthzQuery.data?.length}
                            </Text>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <SearchFilterBar
                                placeholder="Search by email or role"
                                value={search.searchTerm}
                                onReset={search.onClear}
                                onChange={search.onChange}
                            />
                            <Button
                                colorScheme="blue"
                                gap="8px"
                                onClick={inviteDisclosure.onOpen}
                            >
                                <Icon.Plus size="20" /> Invite User
                            </Button>
                        </Box>
                    </Box>
                    <Box flex={1} h="300px">
                        <DataTable<User>
                            state={{
                                columnVisibility: {
                                    menu: menu,
                                },
                            }}
                            variant="unstyled"
                            data={search.filteredOrders}
                            columns={columns}
                            meta={meta}
                        />
                    </Box>
                </Box>
            </PageLayout>
        </>
    );
};

OrganizationDetail.displayName = "OrganizationDetail";
