import { type ChangeEvent, useCallback, useMemo, useState } from "react";

import { debounce } from "throttle-debounce";

const filterFn = <TData,>(
    params: { items: TData[]; keys: (keyof TData)[] },
    searchTerm: string,
) =>
    params.items.filter((order) => {
        const normalizedTerm = searchTerm.toLowerCase();

        return params.keys.some((key) => {
            const value = order[key];

            if (typeof value === "object") {
                return false;
            }

            return String(value).toLowerCase().includes(normalizedTerm);
        });
    });

export const useSearch = <TData,>(params: {
    items: TData[];
    keys: (keyof TData)[];
}) => {
    const [searchTerm, setSearchTerm] = useState<string>("");

    const filteredOrders = useMemo(
        () => filterFn({ items: params.items, keys: params.keys }, searchTerm),
        [params.items, params.keys, searchTerm],
    );

    const onClear = useCallback(() => {
        setSearchTerm("");
    }, []);

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    return { searchTerm, filteredOrders, onClear, onChange };
};

export const useDebouncedSearch = <TData,>(params: {
    isUncontrolled?: boolean;
    items: TData[];
    keys: (keyof TData)[];
}) => {
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    // const [values, setValues] = useState<TData[]>(params.items);

    const debouncedSearchInputValueSetter = useMemo(
        () => debounce(700, setDebouncedSearchTerm, { atBegin: false }),
        [setDebouncedSearchTerm],
    );

    const onSearchInputChange = useCallback(
        (value: string) => {
            // setSearchTerm(value);
            const sanitizedValue = value.toLowerCase().trim();
            debouncedSearchInputValueSetter(sanitizedValue);
        },
        [debouncedSearchInputValueSetter],
    );

    const onClear = useCallback(() => {
        setDebouncedSearchTerm("");
        if (!params.isUncontrolled) {
            setSearchTerm("");
        }
    }, [params.isUncontrolled]);

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onSearchInputChange(event.target.value);
            if (!params.isUncontrolled) {
                setSearchTerm(event.target.value);
            }
        },
        [onSearchInputChange, params.isUncontrolled],
    );

    const filteredOrders = useMemo(
        () =>
            filterFn(
                { items: params.items, keys: params.keys },
                debouncedSearchTerm,
            ),
        [params.items, params.keys, debouncedSearchTerm],
    );

    return {
        filteredOrders,
        onClear,
        onChange,
        searchTerm,
        debouncedSearchTerm,
    };
};
